import { Reveal } from "@progress/kendo-react-animation";
import { Button } from "@progress/kendo-react-buttons";
import {
	ExpansionPanel,
	ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import {
	chevronDownIcon,
	chevronUpIcon,
	eyeIcon,
	fileIcon,
	pencilIcon,
	plusIcon,
	trashIcon,
} from "@progress/kendo-svg-icons";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { DropDownLegStatus } from "../../DropDownLegStatus";
import type { JobDetailsResponse } from "../../api/JobApi";
import { DFlex } from "../../display/DFlex";
import { DGrid } from "../../display/DGrid";
import {
	type LegStatusType,
	jsonListToStringList,
	legTypeNames,
	toCurrency,
} from "../../helpers";
import { DTag, DetailsDisplay } from "../../helpersReact";
import { JobDetailsCard } from "./JobDetailsCard";
import { JobDetailsDisplayLocation } from "./JobDetailsDisplayLocation";
import { JobDetailsInformationCard } from "./JobDetailsInformationCard";

type JobDetailsLegsProps = {
	data: JobDetailsResponse;
	onAddLegClicked: () => void;
	onEditLegClicked: (id: number) => void;
	onRemoveLegClicked: (id: number) => void;
	onViewDocumentsClicked: (id: number) => void;
	onAttachPODClicked: (id: number) => void;
	onCollectionNoteClicked: (id: number) => void;
	onDeliveryTicketClicked: (id: number) => void;
	onSubcontractorOrderClicked: (id: number) => void;
	onLegNewStatusClicked: (id: number, newStatus: LegStatusType) => void;
};
export const JobDetailsLegs = ({
	data,
	onAddLegClicked,
	onEditLegClicked,
	onRemoveLegClicked,
	onViewDocumentsClicked,
	onAttachPODClicked,
	onCollectionNoteClicked,
	onDeliveryTicketClicked,
	onSubcontractorOrderClicked,
	onLegNewStatusClicked,
}: JobDetailsLegsProps) => {
	const [expanded, setExpanded] = useState<number>();
	const sumGrouppedByCurrency = useMemo(() => {
		const groupped = data.legs.reduce<Record<string, number>>((acc, x) => {
			const currencyCode = x.currencyCode ?? "";
			acc[currencyCode] = (acc[currencyCode] ?? 0) + (x.cost ?? 0);
			return acc;
		}, {});
		return Object.entries(groupped)
			.map(([currencyCode, cost]) => ({
				currencyCode,
				cost,
			}))
			.filter((x) => x.cost > 0);
	}, [data.legs]);
	const title = useMemo(() => {
		const title = `Legs (${data.legs.length})`;
		const cost = data.cost ? toCurrency(data.cost, data.currencyCode) : "";
		const sum =
			sumGrouppedByCurrency.length &&
			!sumGrouppedByCurrency.every((x) => x.currencyCode === data.currencyCode)
				? `(${sumGrouppedByCurrency
						.map((x) => toCurrency(x.cost, x.currencyCode))
						.join(", ")})`
				: "";
		return [title, cost, sum].filter(Boolean).join(" - ");
	}, [data.legs, data.cost, data.currencyCode, sumGrouppedByCurrency]);
	return (
		<JobDetailsCard
			title={title}
			buttons={
				<Button
					svgIcon={plusIcon}
					themeColor="primary"
					onClick={onAddLegClicked}
				>
					Add Leg
				</Button>
			}
		>
			{data.legs.map((x) => {
				const hasLocation = x.collectionLocation && x.deliveryLocation;
				const podDocuments = x.documents.filter((x) => x.isPod);
				const nonPodDocuments = x.documents.filter((x) => !x.isPod);
				return (
					<div key={x.id}>
						<ExpansionPanel
							style={{ marginBottom: "8px" }}
							title={
								<DFlex spaceBetween vCenter>
									<DFlex vCenter>
										<DFlex column noGap>
											<DFlex vCenter>
												<b>{legTypeNames[x.type]}</b>
												{x.transferBusinessUnitName && (
													<DFlex vCenter>
														<DTag>Transferred to another Business Unit</DTag>
													</DFlex>
												)}
												{x.cost && (
													<DTag>{toCurrency(x.cost, x.currencyCode)}</DTag>
												)}
											</DFlex>
											<div>
												{hasLocation && (
													<>
														{x.collectionLocation.name} {">>"}{" "}
														{x.deliveryLocation.name}
													</>
												)}
											</div>
										</DFlex>
									</DFlex>
									<DFlex gap="8px" stopPropagation>
										<DFlex vCenter>
											{x.goods.map((x) => (
												<DTag key={x.id}>{x.name}</DTag>
											))}
										</DFlex>
										<DropDownLegStatus
											status={x.status}
											type={x.type}
											onStatusClicked={(newStatus) =>
												onLegNewStatusClicked(x.id, newStatus)
											}
											disabled={!x.actions.allowChangeStatus}
										/>
										<Button
											svgIcon={pencilIcon}
											onClick={() => onEditLegClicked(x.id)}
											disabled={!x.actions.allowEdit}
										/>
										<Button
											svgIcon={trashIcon}
											onClick={() => onRemoveLegClicked(x.id)}
											disabled={!x.actions.allowDelete}
										/>
									</DFlex>
								</DFlex>
							}
							expanded={expanded === x.id}
							onAction={() => setExpanded(x.id === expanded ? undefined : x.id)}
						>
							<Reveal>
								{expanded === x.id && (
									<ExpansionPanelContent>
										<DFlex column>
											{hasLocation && (
												<DGrid columns="repeat(2, 1fr)" gap="8px">
													<JobDetailsInformationCard
														icon={chevronUpIcon}
														title="Start Location"
														value={
															<JobDetailsDisplayLocation
																{...x.collectionLocation}
															/>
														}
													/>
													<JobDetailsInformationCard
														icon={chevronDownIcon}
														title="End Location"
														value={
															<JobDetailsDisplayLocation
																{...x.deliveryLocation}
															/>
														}
													/>
												</DGrid>
											)}
											<DGrid columns="repeat(2, 1fr)" gap="8px">
												<DetailsDisplay
													label="Storage Location"
													value={x.storageLocation}
												/>
												<DetailsDisplay
													label="Storage Start Date"
													value={
														x.storageStartDate
															? dayjs(x.storageStartDate).format(
																	"HH:mm:ss, DD/MM/YYYY",
																)
															: null
													}
												/>
												<DetailsDisplay
													label="Storage End Date"
													value={
														x.storageEndDate
															? dayjs(x.storageEndDate).format(
																	"HH:mm:ss, DD/MM/YYYY",
																)
															: null
													}
												/>
												<DetailsDisplay
													label="Ferry Route"
													value={x.ferryRoute}
												/>
												<DetailsDisplay
													label="Ferry Reference Number"
													value={x.ferryReferenceNumber}
												/>
												<DetailsDisplay
													label="Ferry Sailing Date"
													value={
														x.ferrySailingDate
															? dayjs(x.ferrySailingDate).format(
																	"HH:mm:ss, DD/MM/YYYY",
																)
															: null
													}
												/>
												<DetailsDisplay label="UCR" value={x.ucr} />
												<DetailsDisplay label="MRN" value={x.mrn} />
												<DetailsDisplay label="T1" value={x.t1} />
												<DetailsDisplay
													label="Clearance Location"
													value={x.clearanceLocation}
												/>
												<DetailsDisplay
													label="Clearance Date"
													value={
														x.clearanceDate
															? dayjs(x.clearanceDate).format(
																	"HH:mm:ss, DD/MM/YYYY",
																)
															: null
													}
												/>

												<DetailsDisplay label="Driver" value={x.driverName} />
												<DetailsDisplay label="Truck" value={x.truckName} />
												<DetailsDisplay label="Trailer" value={x.trailerName} />
												<DetailsDisplay
													label="Subcontractor"
													value={x.subcontractorName}
												/>
												<DetailsDisplay
													label="Subcontractor Phones"
													value={jsonListToStringList({
														jsonList: x.subcontractorPhones,
													})}
												/>
												<DetailsDisplay
													label="Transfer Business Unit"
													value={x.transferBusinessUnitName}
												/>
												<DetailsDisplay
													label="Supplier Invoice Number"
													value={x.supplierInvoiceNumber}
												/>
												<DetailsDisplay
													label="Supplier Invoice Date"
													value={
														x.supplierInvoiceDate
															? dayjs(x.supplierInvoiceDate).format(
																	"HH:mm:ss, DD/MM/YYYY",
																)
															: null
													}
												/>
												<DetailsDisplay
													label="Truck Type"
													value={x.truckTypeName}
												/>
												<DetailsDisplay
													label="Trailer Type"
													value={x.trailerTypeName}
												/>
												<DetailsDisplay label="Notes" value={x.notes} />
											</DGrid>
											<DFlex flexEnd wrap>
												<Button
													svgIcon={fileIcon}
													onClick={() => onViewDocumentsClicked(x.id)}
												>
													Documents{" "}
													{nonPodDocuments.length
														? `(${nonPodDocuments.length})`
														: undefined}
												</Button>
												<Button
													svgIcon={fileIcon}
													onClick={() => onAttachPODClicked(x.id)}
												>
													POD{" "}
													{podDocuments.length
														? `(${podDocuments.length})`
														: undefined}
												</Button>

												<Button
													svgIcon={eyeIcon}
													themeColor="primary"
													onClick={() => onCollectionNoteClicked(x.id)}
													disabled={!x.actions.allowGenerateCollectionNote}
												>
													Collection Note
												</Button>
												<Button
													svgIcon={eyeIcon}
													themeColor="primary"
													onClick={() => onDeliveryTicketClicked(x.id)}
													disabled={!x.actions.allowGenerateDeliveryTicket}
												>
													Delivery Ticket
												</Button>
												<Button
													svgIcon={eyeIcon}
													themeColor="primary"
													onClick={() => onSubcontractorOrderClicked(x.id)}
													disabled={!x.actions.allowGenerateSubcontractorOrder}
												>
													Subcontractor Order
												</Button>
											</DFlex>
										</DFlex>
									</ExpansionPanelContent>
								)}
							</Reveal>
						</ExpansionPanel>
					</div>
				);
			})}
		</JobDetailsCard>
	);
};
