import type { MultiSelectChangeEvent } from "@progress/kendo-react-dropdowns";
import {
	Field,
	Form,
	FormElement,
	type FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { useEffect, useState } from "react";
import { ParentType } from "../../../../../../common/models/src/lib/enums/parent-type.enum";
import type { IBase } from "../../../../../../common/models/src/lib/interfaces/base.interface";
import type { IGoodsResponseDto } from "../../../../../../common/models/src/lib/interfaces/goods.interface";
import {
	useAppDispatch,
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../../../../common/stores/src/lib/utils";
import { CelerumAddLocationLabel } from "../../../../../../common/ui/src/lib/components/celerum-add-location-label/celerum-add-location-label.component";
import { CelerumSubmitButton } from "../../../../../../common/ui/src/lib/components/celerum-buttons/celerum-buttons.component";
import {
	CelerumFormDateTimePicker,
	CelerumFormInput,
	CelerumFormInputWithIcon,
	CelerumFormMultiSelect,
	CelerumFormTextArea,
	CelerumSuggestionsInput,
} from "../../../../../../common/ui/src/lib/components/celerum-form-elements/celerum-form-elements.component";
import { SuffixComponent } from "../../../../../../common/ui/src/lib/components/celerum-suffix/celerum-suffix.component";
import { getCurrencySymbol } from "../../../../../../common/utils/src/lib/helpers/currency.helpers";
import { toISOStringWithoutTimezoneOffset } from "../../../../../../common/utils/src/lib/helpers/date.helpers";
import { suggestionsSearchChange } from "../../../../../../common/utils/src/lib/helpers/suggestions-search-change.helpers";
import { useInputChange } from "../../../../../../common/utils/src/lib/hooks/use-input-change.hooks";
import {
	greaterThanZeroValidator,
	noNegativeValidator,
	requiredValidator,
} from "../../../../../../common/utils/src/lib/validators/validators";
import { fetchLegsByJobIdAction } from "../../../../../../legs/data-access/src/lib/legs.slice";
import { fetchLocationsAction } from "../../../../../../locations/data-access/src/lib/locations.slice";
import { LocationForm } from "../../../../../../locations/feature/src/lib/components/location-form/location-form.component";
import {
	createGoodsAction,
	updateGoodsAction,
} from "../../../../../data-access/src/lib/goods.slice";
import styles from "./goods-form.module.css";

interface GoodsFormProps {
	formState: IGoodsResponseDto | undefined;
	parentId: number | string | undefined;
	parentType: ParentType;
	onClose: () => void;
}

export const GoodsForm = ({
	formState,
	onClose,
	parentId,
	parentType,
}: GoodsFormProps) => {
	const dispatchWithNotifications = useAppDispatchWithNotifications();
	const dispatch = useAppDispatch();
	const handleInputChange = useInputChange();

	const { constraints, loading, locations, locationLoading, customer } =
		useAppSelector((state) => ({
			constraints: state.constraints.data,
			loading: state.goods.loading,
			locations: state.locations.data,
			locationLoading: state.locations.loading,
			customer: state.jobs.job?.customer,
		}));

	const [constraintIds, setConstraintIds] = useState<number[]>([]);
	const [collectionDate, setCollectionDate] = useState(
		formState ? formState.collectionDate : new Date(),
	);

	const [showLocationForm, setShowLocationForm] = useState<boolean>(false);
	const [formData, setFormData] = useState<IGoodsResponseDto | undefined>(
		formState || ({} as IGoodsResponseDto),
	);
	const [fieldToUpdate, setFieldToUpdate] = useState<string>();
	const [isFormModified, setIsFormModified] = useState<boolean>(false);

	const handleSearchLocationsChange = suggestionsSearchChange({
		dispatchFunction: dispatch,
		searchFields: ["name", "address"],
		reduxAction: fetchLocationsAction,
	});

	const onConstraintsDropdownChange = (event: MultiSelectChangeEvent) => {
		const selectedConstraints = event.target.value;
		handleInputChange(event, "constraints", setFormData);
		setConstraintIds(
			selectedConstraints.map((constraint: IBase) => constraint.id),
		);
	};

	const handleSubmit = async (event: FormSubmitClickEvent) => {
		const {
			id,
			name,
			revenue = undefined,
			quantity = undefined,
			weight = undefined,
			notes = undefined,
			collectionDate,
			deliveryDate,
		} = event.values;

		if (!event.isValid) {
			return;
		}

		const requestObject = {
			id,
			name,
			revenue,
			quantity,
			weight,
			notes,
			currencyId: customer?.currency.id ?? 1,
			constraintIds: constraintIds.length ? constraintIds : [],
			collectionDate: toISOStringWithoutTimezoneOffset(
				new Date(collectionDate),
			),
			deliveryDate: toISOStringWithoutTimezoneOffset(new Date(deliveryDate)),
			collectionLocationId: formData?.collectionLocation?.id ?? undefined,
			deliveryLocationId: formData?.deliveryLocation?.id ?? undefined,
			jobId: parentType === ParentType.Job ? parentId : null,
		};

		if (formState) {
			const actionResult = await dispatchWithNotifications({
				action: updateGoodsAction,
				payload: requestObject,
				successMessage: `Goods named ${name} successfully updated.`,
				errorMessage: `Could not update goods named ${name}.`,
			});
			if (updateGoodsAction.fulfilled.match(actionResult)) {
				dispatchWithNotifications({
					action: fetchLegsByJobIdAction,
					payload: Number(parentId),
					errorMessage: "Could not fetch legs.",
				});
				onClose();
			}
		} else {
			const actionResult = await dispatchWithNotifications({
				action: createGoodsAction,
				payload: requestObject,
				successMessage: `Goods named ${name} successfully created.`,
				errorMessage: `Could not create goods named ${name}.`,
			});
			createGoodsAction.fulfilled.match(actionResult) && onClose();
		}
	};

	useEffect(() => {
		if (formState) {
			setConstraintIds(
				formState.constraints.map((constraint: IBase) => constraint.id),
			);
		}
	}, [formState]);

	const renderGoodsForm = () => (
		<Form
			onSubmitClick={handleSubmit}
			initialValues={formData || formState || {}}
			render={(formRenderProps) => {
				return (
					<FormElement>
						<fieldset className="k-form-fieldset">
							<div className={styles.formColumns}>
								<div>
									<Field
										id="name"
										label="Goods Description"
										name="name"
										maxLength={500}
										component={CelerumFormTextArea}
										type="text"
										validator={requiredValidator}
										required
										defaultValue=""
										focused="true"
										onChange={(event) =>
											handleInputChange(event, "name", setFormData)
										}
									/>
									<Field
										id="revenue"
										name="revenue"
										label="Revenue"
										component={CelerumFormInputWithIcon}
										onChange={(event) =>
											handleInputChange(event, "revenue", setFormData)
										}
										maxLength={250}
										validator={[requiredValidator, noNegativeValidator]}
										placeholder="0.00"
										type="number"
										required
									>
										<SuffixComponent
											value={
												customer?.currency.code
													? getCurrencySymbol(customer.currency.code)
													: getCurrencySymbol("GBP")
											}
										/>
									</Field>
									<Field
										id="quantity"
										label="Quantity"
										name="quantity"
										maxLength={500}
										component={CelerumFormInput}
										type="number"
										onChange={(event) =>
											handleInputChange(event, "quantity", setFormData)
										}
										validator={[requiredValidator, greaterThanZeroValidator]}
										required
									/>
									<Field
										id="weight"
										label="Weight"
										name="weight"
										maxLength={500}
										component={CelerumFormInputWithIcon}
										type="number"
										onChange={(event) =>
											handleInputChange(event, "weight", setFormData)
										}
										validator={[requiredValidator, greaterThanZeroValidator]}
										required
									>
										<SuffixComponent value="Kg" />
									</Field>
									<Field
										label="Special Requirements"
										id="constraints"
										name="constraints"
										dataItemKey="id"
										textField="name"
										data={constraints}
										onChange={onConstraintsDropdownChange}
										component={CelerumFormMultiSelect}
									/>
								</div>
								<div>
									<Field
										id="collectionLocation"
										name="collectionLocation"
										dataItemKey="id"
										textField="nameAndAddress"
										component={CelerumSuggestionsInput}
										label="Collection Location"
										handleSearchChange={handleSearchLocationsChange}
										data={locations}
										loading={locationLoading}
										onChange={(event) =>
											handleInputChange(
												event,
												"collectionLocation",
												setFormData,
											)
										}
										onFooterClick={() => {
											setShowLocationForm(true);
											setFieldToUpdate("collectionLocation");
										}}
										footer={<CelerumAddLocationLabel />}
									/>
									<Field
										id="collectionDate"
										name="collectionDate"
										label="Collection Date"
										component={CelerumFormDateTimePicker}
										validator={requiredValidator}
										required
										onChange={(event) => {
											setCollectionDate(event.target.value);
											handleInputChange(event, "collectionDate", setFormData);
										}}
									/>
									<Field
										name="deliveryLocation"
										component={CelerumSuggestionsInput}
										label="Delivery Location"
										dataItemKey="id"
										textField="nameAndAddress"
										onChange={(event) =>
											handleInputChange(event, "deliveryLocation", setFormData)
										}
										handleSearchChange={handleSearchLocationsChange}
										data={locations}
										loading={locationLoading}
										onFooterClick={() => {
											setShowLocationForm(true);
											setFieldToUpdate("deliveryLocation");
										}}
										footer={<CelerumAddLocationLabel />}
									/>

									<Field
										id="deliveryDate"
										name="deliveryDate"
										label="Delivery Date"
										component={CelerumFormDateTimePicker}
										validator={requiredValidator}
										onChange={(event) =>
											handleInputChange(event, "deliveryDate", setFormData)
										}
										required
										min={collectionDate}
									/>
									<Field
										id="notes"
										label="Goods Notes"
										name="notes"
										maxLength={500}
										component={CelerumFormTextArea}
										type="text"
										defaultValue={""}
										onChange={(event) =>
											handleInputChange(event, "notes", setFormData)
										}
									/>
								</div>
							</div>
						</fieldset>
						<CelerumSubmitButton
							type="submit"
							disabled={
								(!isFormModified && !formRenderProps.allowSubmit) || loading
							}
							title="Submit"
						/>
					</FormElement>
				);
			}}
		/>
	);

	const renderLocationForm = () => (
		<LocationForm<IGoodsResponseDto>
			onClose={() => {
				setShowLocationForm(false);
			}}
			addLocationFromAnotherForm
			fieldToSet={fieldToUpdate}
			setFormData={setFormData}
			setIsParentFormModified={setIsFormModified}
		/>
	);

	return !showLocationForm ? renderGoodsForm() : renderLocationForm();
};
