import {
	Field,
	Form,
	FormElement,
	type FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { type Dispatch, type SetStateAction, useEffect, useState } from "react";
import { RADIO_BUTTON } from "../../../../../../common/models/src/lib/constants/invoice.constants";
import { CustomerStatus } from "../../../../../../common/models/src/lib/enums/customer.enum";
import { ModalSize } from "../../../../../../common/models/src/lib/enums/modal.enums";
import type { ICurrency } from "../../../../../../common/models/src/lib/interfaces/currency.interface";
import type { IJobDetails } from "../../../../../../common/models/src/lib/interfaces/job.interface";
import {
	useAppDispatch,
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../../../../common/stores/src/lib/utils";
import { CelerumAddLocationLabel } from "../../../../../../common/ui/src/lib/components/celerum-add-location-label/celerum-add-location-label.component";
import { CelerumSubmitButton } from "../../../../../../common/ui/src/lib/components/celerum-buttons/celerum-buttons.component";
import {
	CelerumFormDateTimePicker,
	CelerumFormDropDownList,
	CelerumFormInput,
	CelerumFormInputWithIcon,
	CelerumFormTextArea,
	CelerumSuggestionsInput,
} from "../../../../../../common/ui/src/lib/components/celerum-form-elements/celerum-form-elements.component";
import { SuffixComponent } from "../../../../../../common/ui/src/lib/components/celerum-suffix/celerum-suffix.component";
import { getCurrencySymbol } from "../../../../../../common/utils/src/lib/helpers/currency.helpers";
import { toISOStringWithoutTimezoneOffset } from "../../../../../../common/utils/src/lib/helpers/date.helpers";
import { suggestionsSearchChange } from "../../../../../../common/utils/src/lib/helpers/suggestions-search-change.helpers";
import { useInputChange } from "../../../../../../common/utils/src/lib/hooks/use-input-change.hooks";
import { requiredValidator } from "../../../../../../common/utils/src/lib/validators/validators";
import { fetchCustomersAction } from "../../../../../../customers/data-access/src/lib/customers.slice";
import { fetchLocationsAction } from "../../../../../../locations/data-access/src/lib/locations.slice";
import { LocationForm } from "../../../../../../locations/feature/src/lib/components/location-form/location-form.component";
import {
	fetchJobByIdAction,
	updateJobAction,
} from "../../../../../data-access/src/lib/jobs.slice";
import styles from "./edit-job-information-form.module.css";

interface EditJobInformationFormProps {
	formState: IJobDetails;
	onClose: () => void;
	setModalSize: Dispatch<SetStateAction<ModalSize>>;
}

export const EditJobInformationForm = ({
	formState,
	onClose,
	setModalSize,
}: EditJobInformationFormProps) => {
	const dispatch = useAppDispatch();
	const dispatchWithNotifications = useAppDispatchWithNotifications();
	const handleInputChange = useInputChange();

	const { users, jobTypes, customers, locations } = useAppSelector((state) => ({
		users: state.authentication.users,
		jobTypes: state.jobTypes.data,
		customers: state.customers.data,
		locations: state.locations.data,
		organisation: state.organisations.organisation,
	}));

	const [startDate, setStartDate] = useState(formState.startDate);
	const [currency, setCurrency] = useState<ICurrency>(
		formState?.customer?.currency,
	);
	const [assignedToId, setAssignedToId] = useState<string | number>(
		formState.assignedUser?.id,
	);
	const [formData, setFormData] = useState<IJobDetails | undefined>({
		...formState,
		useVat: formState.useVat ? "Yes" : "No",
	} as IJobDetails);

	const [showLocationForm, setShowLocationForm] = useState<boolean>(false);
	const [fieldToUpdate, setFieldToUpdate] = useState<string>();
	const [isFormModified, setIsFormModified] = useState<boolean>(false);

	const handleSearchCustomersChange = suggestionsSearchChange({
		dispatchFunction: dispatch,
		searchFields: ["name"],
		reduxAction: fetchCustomersAction,
		extraTypesLists: [[{ id: "status", value: [CustomerStatus.ACTIVE] }]],
	});

	const handleSearchLocationsChange = suggestionsSearchChange({
		dispatchFunction: dispatch,
		searchFields: ["name", "address"],
		reduxAction: fetchLocationsAction,
	});

	const handleSubmit = async (event: FormSubmitClickEvent) => {
		if (!event.isValid) {
			return;
		}

		const {
			id,
			startDate,
			endDate,
			purchaseOrderNumber,
			notes,
			price,
			startLocation,
			endLocation,
			customer,
			jobType,
			useVat,
			containerNumber,
			containerPin,
			containerSealNumber,
		} = event.values;

		const requestObject = {
			id,
			startDate: toISOStringWithoutTimezoneOffset(new Date(startDate)),
			endDate: toISOStringWithoutTimezoneOffset(new Date(endDate)),
			purchaseOrderNumber,
			notes,
			price,
			startLocationId: startLocation.id,
			endLocationId: endLocation.id,
			customerId: customer.id,
			jobTypeId: jobType.id,
			assignedTo: assignedToId,
			useVat: useVat === "Yes",
			containerNumber,
			containerPin,
			containerSealNumber,
		};

		const actionResult = await dispatchWithNotifications({
			action: updateJobAction,
			payload: { ...formState, ...requestObject },
			successMessage: `Job with ID ${formState.uniqueId} was successfully updated.`,
			errorMessage: `Could not update job with ID ${formState.uniqueId}.`,
		});

		if (updateJobAction.fulfilled.match(actionResult)) {
			dispatchWithNotifications({
				action: fetchJobByIdAction,
				payload: Number(formState.id),
				errorMessage: `Could not fetch job with ID ${formState.uniqueId}.`,
			});
			onClose();
		}
	};

	useEffect(() => {
		showLocationForm
			? setModalSize(ModalSize.Medium)
			: setModalSize(ModalSize.Large);
	}, [setModalSize, showLocationForm]);

	const renderJobForm = () => (
		<Form
			onSubmitClick={handleSubmit}
			initialValues={formData}
			render={(formRenderProps) => (
				<FormElement>
					<fieldset className="k-form-fieldset">
						<div className={styles.formContainer}>
							<div>
								<Field
									id="jobType"
									dataItemKey="id"
									label="Job Type"
									textField="name"
									name="jobType"
									component={CelerumFormDropDownList}
									onChange={(event) => {
										handleInputChange(event, "jobType", setFormData);
									}}
									data={jobTypes}
									validator={requiredValidator}
									required
								/>
								<Field
									id="customer"
									dataItemKey="id"
									label="Customer"
									textField="name"
									name="customer"
									component={CelerumSuggestionsInput}
									data={customers}
									validator={requiredValidator}
									handleSearchChange={handleSearchCustomersChange}
									required
									onChange={(event) =>
										setCurrency(event?.target?.value?.currency)
									}
								/>
								<Field
									label="Assigned To"
									id="assignedUser"
									name="assignedUser"
									textField="fullName"
									dataItemKey="id"
									component={CelerumFormDropDownList}
									onChange={(event) => setAssignedToId(event.target.value.id)}
									data={users}
									validator={requiredValidator}
									required
								/>
								<Field
									id="startLocation"
									dataItemKey="id"
									label="Start Location"
									textField="nameAndAddress"
									name="startLocation"
									component={CelerumSuggestionsInput}
									data={locations}
									handleSearchChange={handleSearchLocationsChange}
									onChange={(event) => {
										handleInputChange(event, "startLocation", setFormData);
									}}
									validator={requiredValidator}
									onFooterClick={() => {
										setShowLocationForm(true);
										setFieldToUpdate("startLocation");
									}}
									required
									footer={<CelerumAddLocationLabel />}
								/>
								<Field
									id="startDate"
									name="startDate"
									label="Start Date"
									component={CelerumFormDateTimePicker}
									validator={requiredValidator}
									required
									onChange={(event) => setStartDate(event.target.value)}
								/>
								<Field
									id="useVat"
									name="useVat"
									label="VAT"
									component={CelerumFormDropDownList}
									data={RADIO_BUTTON}
									validator={requiredValidator}
									required
									onChange={(event) => {
										handleInputChange(event, "useVat", setFormData);
									}}
								/>
							</div>
							<div>
								<Field
									id="purchaseOrderNumber"
									label="Purchase Order Number"
									name="purchaseOrderNumber"
									maxLength={500}
									component={CelerumFormInput}
									required
									validator={requiredValidator}
									onChange={(event) => {
										handleInputChange(
											event,
											"purchaseOrderNumber",
											setFormData,
										);
									}}
								/>
								<Field
									id="endLocation"
									dataItemKey="id"
									label="End Location"
									textField="nameAndAddress"
									name="endLocation"
									component={CelerumSuggestionsInput}
									data={locations}
									handleSearchChange={handleSearchLocationsChange}
									onChange={(event) => {
										handleInputChange(event, "endLocation", setFormData);
									}}
									validator={requiredValidator}
									onFooterClick={() => {
										setShowLocationForm(true);
										setFieldToUpdate("endLocation");
									}}
									required
									footer={<CelerumAddLocationLabel />}
								/>
								<Field
									id="endDate"
									name="endDate"
									label="End Date"
									min={startDate}
									component={CelerumFormDateTimePicker}
									validator={requiredValidator}
									onChange={(event) => {
										handleInputChange(event, "endDate", setFormData);
									}}
									required
								/>
								<Field
									id="price"
									name="price"
									label="Price"
									component={CelerumFormInputWithIcon}
									maxLength={250}
									onChange={(event) => {
										handleInputChange(event, "price", setFormData);
									}}
									placeholder="0.00"
								>
									<SuffixComponent
										value={
											currency?.code
												? getCurrencySymbol(currency.code)
												: getCurrencySymbol("GBP")
										}
									/>
								</Field>
								<Field
									id="notes"
									label="Notes"
									name="notes"
									component={CelerumFormTextArea}
									onChange={(event) => {
										handleInputChange(event, "notes", setFormData);
									}}
									type="text"
									defaultValue={""}
									maxLength={500}
								/>
							</div>
							{formData?.jobType.isContainer && (
								<div>
									<Field
										id="containerNumber"
										name="containerNumber"
										onChange={(event) =>
											handleInputChange(event, "containerNumber", setFormData)
										}
										label="Container Number"
										maxLength={250}
										component={CelerumFormInput}
										type="text"
									/>
									<Field
										id="containerPin"
										name="containerPin"
										onChange={(event) =>
											handleInputChange(event, "containerPin", setFormData)
										}
										label="Container Pin"
										maxLength={250}
										component={CelerumFormInput}
										type="text"
									/>
									<Field
										id="containerSealNumber"
										name="containerSealNumber"
										onChange={(event) =>
											handleInputChange(
												event,
												"containerSealNumber",
												setFormData,
											)
										}
										label="Container Seal Number"
										maxLength={250}
										component={CelerumFormInput}
										type="text"
									/>
								</div>
							)}
						</div>
					</fieldset>
					<CelerumSubmitButton
						type="submit"
						disabled={!isFormModified && !formRenderProps.allowSubmit}
						title="Submit"
					/>
				</FormElement>
			)}
		/>
	);

	const renderLocationForm = () => (
		<LocationForm<IJobDetails>
			onClose={() => {
				setShowLocationForm(false);
			}}
			addLocationFromAnotherForm
			fieldToSet={fieldToUpdate}
			setFormData={setFormData}
			setIsParentFormModified={setIsFormModified}
		/>
	);

	return !showLocationForm ? renderJobForm() : renderLocationForm();
};
