import { TextArea } from "@progress/kendo-react-inputs";
import { useQuery } from "@tanstack/react-query";
import { Fragment, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { GLOBALS } from "../_GLOBALS";
import type { LegFormDataResponse } from "../api/JobApi";
import { type Leg, LegForm } from "../forms/LegForm";
import {
	NewLegType,
	NewSupplierInvoiceType,
	jobApi,
	toasted,
	usRecommendationSettings,
	useLookupBusinessUnitsForTransfer,
	useLookupCurrencies,
	useLookupDrivers,
	useLookupGoods,
	useLookupLegTypes,
	useLookupLocations,
	useLookupSubcontractors,
	useLookupSupplierInvoiceTypes,
	useLookupTrailerTypes,
	useLookupTrailers,
	useLookupTruckTypes,
	useLookupTrucks,
} from "../helpers";
import { useDialog } from "../helpersReact";
import { useLocationFormDialog } from "./useLocationFormDialog";
import {
	type Recommendation,
	useRecommendationsDialog,
} from "./useRecommendationsDialog";

type LegSelfFormProps = {
	id?: number;
	jobId?: number;
	onSubmit?: (data: Leg) => void;
	isRecEnabled?: boolean;
	onRecommendationsButtonClick?: () => void;
	onChange?: (data: Leg) => void;
	showLocationForm?: (callback: (value: number) => void) => void;
};
const LegSelfForm = ({
	id,
	jobId,
	onSubmit,
	onRecommendationsButtonClick,
	isRecEnabled,
	onChange,
	showLocationForm,
}: LegSelfFormProps) => {
	const data = useQuery({
		queryKey: ["jobApi.bff.bffLegFormDataDetail", id, jobId],
		queryFn: () => {
			if (jobId) {
				return {
					jobId,
					goodsIds: [],
					legType: NewLegType.Delivery,
					supplierInvoice: NewSupplierInvoiceType.NoInvoice,
				} satisfies LegFormDataResponse;
			}
			return jobApi.bff
				.bffLegFormDataDetail(Number(id))
				.then((data) => data.data);
		},
	});
	const lLegTypes = useLookupLegTypes();
	const lGoods = useLookupGoods(data.data?.jobId ?? undefined);
	const [locationCreated, setLocationCreated] = useState(0);
	const lLocations = useLookupLocations(
		[data.data?.collectionLocationId, data.data?.deliveryLocationId],
		[locationCreated],
	);
	const lSubcontractors = useLookupSubcontractors();
	const lDrivers = useLookupDrivers();
	const lTrucks = useLookupTrucks();
	const lTrailers = useLookupTrailers();
	const lBusinessUnits = useLookupBusinessUnitsForTransfer();
	const lTrailerTypes = useLookupTrailerTypes();
	const lTruckTypes = useLookupTruckTypes();
	const lSupplierInvoiceTypes = useLookupSupplierInvoiceTypes();
	const lCurrencies = useLookupCurrencies();
	const defaultValues = useMemo(
		() => ({
			...data.data,
			collectionDate: data.data?.collectionDate
				? new Date(data.data.collectionDate)
				: undefined,
			deliveryDate: data.data?.deliveryDate
				? new Date(data.data.deliveryDate)
				: undefined,
			supplierInvoiceDate: data.data?.supplierInvoiceDate
				? new Date(data.data.supplierInvoiceDate)
				: undefined,
			storageStartDate: data.data?.storageStartDate
				? new Date(data.data.storageStartDate)
				: undefined,
			storageEndDate: data.data?.storageEndDate
				? new Date(data.data.storageEndDate)
				: undefined,
			ferrySailingDate: data.data?.ferrySailingDate
				? new Date(data.data.ferrySailingDate)
				: undefined,
			clearanceDate: data.data?.clearanceDate
				? new Date(data.data.clearanceDate)
				: undefined,
		}),
		[data.data],
	);
	if (!data.isFetchedAfterMount || !data.data?.jobId) {
		return <div>Loading...</div>;
	}
	return (
		<LegForm
			isRecEnabled={isRecEnabled}
			onRecommendationsButtonClick={onRecommendationsButtonClick}
			lLegTypes={lLegTypes}
			lGoods={lGoods}
			lLocations={lLocations}
			lSubcontractors={lSubcontractors}
			lDrivers={lDrivers}
			lTrucks={lTrucks}
			lTrailers={lTrailers}
			lBusinessUnits={lBusinessUnits}
			lTrailerTypes={lTrailerTypes}
			lTruckTypes={lTruckTypes}
			lSupplierInvoiceTypes={lSupplierInvoiceTypes}
			lCurrencies={lCurrencies}
			defaultValues={defaultValues}
			onSubmit={async (data) => {
				await toasted(
					jobApi.bff.bffLegFormDataCreate(data as LegFormDataResponse),
					"Saving leg",
				);
				onSubmit?.(data);
			}}
			onChange={onChange}
			onGoodsIdsChange={(value, setValue) => {
				const firstValue = value[0];
				if (!firstValue) return;
				const goodId = Number(firstValue);
				jobApi.bff
					.bffLegFormDataGoodsDetail(goodId)
					.then((x) => x.data)
					.then((x) => {
						setValue("collectionLocationId", x.collectionLocationId);
						x.collectionDate &&
							setValue("collectionDate", new Date(x.collectionDate));
						setValue("deliveryLocationId", x.deliveryLocationId);
						x.deliveryDate &&
							setValue("deliveryDate", new Date(x.deliveryDate));
					});
			}}
			onOperationIdsChange={(data, formValues, setValue) => {
				if ("subcontractorId" in data) {
					if (data.subcontractorId) {
						setValue("supplierInvoice", NewSupplierInvoiceType.ToBeInvoiced);
					} else {
						setValue("supplierInvoice", NewSupplierInvoiceType.NoInvoice);
					}
				}
				if (
					data.subcontractorId &&
					(!formValues.driverId || !formValues.truckId || !formValues.trailerId)
				) {
					jobApi.bff
						.bffFleetLookupCreate({ subcontractorId: data.subcontractorId })
						.then((x) => x.data)
						.then((x) => {
							formValues.driverId ||
								(x.driverId && setValue("driverId", x.driverId));
							formValues.truckId ||
								(x.truckId && setValue("truckId", x.truckId));
							formValues.trailerId ||
								(x.trailerId && setValue("trailerId", x.trailerId));
						});
				}
				if (
					data.driverId &&
					(!formValues.truckId ||
						!formValues.trailerId ||
						!formValues.subcontractorId)
				) {
					jobApi.bff
						.bffFleetLookupCreate({ driverId: data.driverId })
						.then((x) => x.data)
						.then((x) => {
							formValues.truckId ||
								(x.truckId && setValue("truckId", x.truckId));
							formValues.trailerId ||
								(x.trailerId && setValue("trailerId", x.trailerId));
							formValues.subcontractorId ||
								(x.subcontractorId &&
									setValue("subcontractorId", x.subcontractorId));
						});
				}
				if (
					data.truckId &&
					(!formValues.driverId ||
						!formValues.trailerId ||
						!formValues.subcontractorId)
				) {
					jobApi.bff
						.bffFleetLookupCreate({ truckId: data.truckId })
						.then((x) => x.data)
						.then((x) => {
							formValues.driverId ||
								(x.driverId && setValue("driverId", x.driverId));
							formValues.trailerId ||
								(x.trailerId && setValue("trailerId", x.trailerId));
							formValues.subcontractorId ||
								(x.subcontractorId &&
									setValue("subcontractorId", x.subcontractorId));
						});
				}
				if (
					data.trailerId &&
					(!formValues.driverId ||
						!formValues.truckId ||
						!formValues.subcontractorId)
				) {
					jobApi.bff
						.bffFleetLookupCreate({ trailerId: data.trailerId })
						.then((x) => x.data)
						.then((x) => {
							formValues.driverId ||
								(x.driverId && setValue("driverId", x.driverId));
							formValues.truckId ||
								(x.truckId && setValue("truckId", x.truckId));
							formValues.subcontractorId ||
								(x.subcontractorId &&
									setValue("subcontractorId", x.subcontractorId));
						});
				}
			}}
			onAddLocation={
				showLocationForm
					? (x) => {
							showLocationForm((id) => {
								x(id);
								setLocationCreated((x) => x + 1);
							});
						}
					: undefined
			}
		/>
	);
};

export const useLegFormDialog = () => {
	const [props, setProps] = useState<LegSelfFormProps>();
	const [formData, setFormData] = useState<Leg>();
	const [recommendation, setRecommendation] = useState<Recommendation>();
	const recommendationSettingsData = usRecommendationSettings();
	const handleRecommendationSelected = (x: Recommendation) => {
		const form = GLOBALS.legForm;
		setRecommendation(x);
		if (!form) return console.error("Form not found");
		form.setValue("driverId", x.driverId ?? null, { shouldDirty: true });
		form.setValue("truckId", x.truckId ?? null, { shouldDirty: true });
		form.setValue("trailerId", x.trailerId ?? null, { shouldDirty: true });
		form.setValue("subcontractorId", x.subcontractorId ?? null, {
			shouldDirty: true,
		});
		toggleRecDialog(false);
	};
	const [toggleRecDialog, recDialog, isRecEnabled] = useRecommendationsDialog(
		formData,
		handleRecommendationSelected,
	);
	const [showLocationForm, locationFormDialog] = useLocationFormDialog();
	const handleSubmit = (data: Leg) => {
		if (!recommendationSettingsData.data.length) {
			return;
		}
		const closestDrivers = GLOBALS.recommendationsClosestDrivers;
		const randomDrivers = GLOBALS.recommendationsRandomDrivers;
		GLOBALS.recommendationsClosestDrivers = [];
		GLOBALS.recommendationsRandomDrivers = [];
		const form = GLOBALS.legForm;
		const legId = data.id;
		if (!form) return console.error("Form not found");
		if (!data._isOperationDirty) return console.log("No operation changes");
		if (!legId) return console.error("Leg ID not found");
		const recommendations = { closestDrivers, randomDrivers };
		const selectedRecommendation =
			recommendation?.type === "ClosestDrivers"
				? closestDrivers[recommendation.index]
				: recommendation?.type === "RandomDrivers"
					? randomDrivers[recommendation.index]
					: undefined;
		const isRecommendationChanged =
			(selectedRecommendation?.driverId ?? null) !== (data.driverId ?? null) ||
			(selectedRecommendation?.truckId ?? null) !== (data.truckId ?? null) ||
			(selectedRecommendation?.trailerId ?? null) !== (data.trailerId ?? null);
		let feedback = "";
		toast.info(
			(props) => (
				<>
					<div>
						<h3>Allocation feedback</h3>
						<p>
							Please provide feedback on the allocation. This will help us
							improve the recommendation engine. Close this dialog when you are
							done. Thank you!
						</p>
					</div>
					<TextArea
						rows={5}
						onChange={(x) => {
							toast.update(props.toastProps.toastId, { autoClose: false });
							feedback = x.value;
						}}
					/>
				</>
			),
			{
				onClose: () => {
					jobApi.bff.bffRecommendationFeedbackCreate({
						legId,
						recommendations,
						feedback,
						index: isRecommendationChanged ? undefined : recommendation?.index,
						type: isRecommendationChanged ? undefined : recommendation?.type,
					});
				},
			},
		);
	};
	const [toggleDialog, dialog] = useDialog(
		<div style={{ minWidth: 1000 }}>
			<LegSelfForm
				{...props}
				onSubmit={(x) => {
					handleSubmit(x);
					props?.onSubmit?.(x);
				}}
				showLocationForm={showLocationForm}
				onRecommendationsButtonClick={toggleRecDialog}
				isRecEnabled={isRecEnabled}
				onChange={setFormData}
			/>
		</div>,
		props?.id ? "Edit Leg" : "Add Leg",
	);
	const openDialog = (props?: LegSelfFormProps) => {
		setRecommendation(undefined);
		setProps(props);
		toggleDialog(props);
	};
	return [
		openDialog,
		<Fragment key="">
			{dialog}
			{locationFormDialog}
			{recDialog}
		</Fragment>,
	] as const;
};
