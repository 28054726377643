import {
	Field,
	Form,
	FormElement,
	type FormProps,
} from "@progress/kendo-react-form";
import { type Dispatch, type SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CustomerStatus } from "../../../../../../common/models/src/lib/enums/customer.enum";
import { ModalSize } from "../../../../../../common/models/src/lib/enums/modal.enums";
import type { ICurrency } from "../../../../../../common/models/src/lib/interfaces/currency.interface";
import type { IJobDetails } from "../../../../../../common/models/src/lib/interfaces/job.interface";
import {
	useAppDispatch,
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../../../../common/stores/src/lib/utils";
import { CelerumAddLocationLabel } from "../../../../../../common/ui/src/lib/components/celerum-add-location-label/celerum-add-location-label.component";
import { CelerumSubmitButton } from "../../../../../../common/ui/src/lib/components/celerum-buttons/celerum-buttons.component";
import {
	CelerumFormDateTimePicker,
	CelerumFormDropDownList,
	CelerumFormInput,
	CelerumFormInputWithIcon,
	CelerumFormTextArea,
	CelerumSuggestionsInput,
} from "../../../../../../common/ui/src/lib/components/celerum-form-elements/celerum-form-elements.component";
import { SuffixComponent } from "../../../../../../common/ui/src/lib/components/celerum-suffix/celerum-suffix.component";
import { getCurrencySymbol } from "../../../../../../common/utils/src/lib/helpers/currency.helpers";
import { toISOStringWithoutTimezoneOffset } from "../../../../../../common/utils/src/lib/helpers/date.helpers";
import { suggestionsSearchChange } from "../../../../../../common/utils/src/lib/helpers/suggestions-search-change.helpers";
import { useInputChange } from "../../../../../../common/utils/src/lib/hooks/use-input-change.hooks";
import { requiredValidator } from "../../../../../../common/utils/src/lib/validators/validators";
import { fetchCustomersAction } from "../../../../../../customers/data-access/src/lib/customers.slice";
import { fetchLocationsAction } from "../../../../../../locations/data-access/src/lib/locations.slice";
import { LocationForm } from "../../../../../../locations/feature/src/lib/components/location-form/location-form.component";
import { duplicateJobAction } from "../../../../../data-access/src/lib/jobs.slice";
import styles from "./duplicate-job-form.module.css";

interface DuplicateJobFormProps {
	formState: IJobDetails;
	onClose: () => void;
	setModalSize: Dispatch<SetStateAction<ModalSize>>;
}

export const DuplicateJobForm = ({
	formState,
	onClose,
	setModalSize,
}: DuplicateJobFormProps) => {
	const dispatchWithNotifications = useAppDispatchWithNotifications();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const handleInputChange = useInputChange();

	const { jobTypes, customers, users, loadingCustomers, locations } =
		useAppSelector((state) => ({
			jobTypes: state.jobTypes.data,
			customers: state.customers.data,
			loadingCustomers: state.customers.loading,
			users: state.authentication.users,
			locations: state.locations.data,
		}));

	const [startDate, setStartDate] = useState(formState.startDate);
	const [currency, setCurrency] = useState<ICurrency>(
		formState?.customer?.currency,
	);
	const [showLocationForm, setShowLocationForm] = useState<boolean>(false);
	const [formData, setFormData] = useState<IJobDetails | undefined>(
		formState as IJobDetails,
	);
	const [fieldToUpdate, setFieldToUpdate] = useState<string>();

	const handleSearchLocationsChange = suggestionsSearchChange({
		dispatchFunction: dispatch,
		searchFields: ["name", "address"],
		reduxAction: fetchLocationsAction,
	});

	const handleSearchCustomersChange = suggestionsSearchChange({
		dispatchFunction: dispatch,
		searchFields: ["name"],
		reduxAction: fetchCustomersAction,
		extraTypesLists: [[{ id: "status", value: [CustomerStatus.ACTIVE] }]],
	});

	const setCustomerCurrency = (id: number) => {
		const customer = customers.find((customer) => customer.id === id);

		if (!customer) return;
		setCurrency(customer.currency);
	};

	const handleSubmit: FormProps["onSubmit"] = async (dataItem) => {
		const {
			id,
			startDate,
			endDate,
			purchaseOrderNumber,
			notes,
			price,
			startLocation,
			endLocation,
			customer,
			jobType,
			assignedUser,
			containerNumber,
			containerPin,
			containerSealNumber,
		} = dataItem;

		const requestObject = {
			id,
			startDate: toISOStringWithoutTimezoneOffset(new Date(startDate)),
			endDate: toISOStringWithoutTimezoneOffset(new Date(endDate)),
			purchaseOrderNumber,
			notes,
			price,
			startLocationId: startLocation?.id ?? null,
			endLocationId: endLocation.id ?? null,
			customerId: customer.id,
			jobTypeId: jobType.id,
			assignedTo: assignedUser.id,
			containerNumber,
			containerPin,
			containerSealNumber,
		};

		const actionResult = await dispatchWithNotifications({
			action: duplicateJobAction,
			payload: { ...formState, ...requestObject },
			errorMessage: `Could not duplicate job with ID ${formState.uniqueId}.`,
		});

		onClose();

		if (duplicateJobAction.fulfilled.match(actionResult)) {
			navigate(`/jobs/${actionResult.payload.id}`, {
				state: { from: window.location.pathname },
			});
		}
	};

	useEffect(() => {
		showLocationForm
			? setModalSize(ModalSize.Medium)
			: setModalSize(ModalSize.Large);
	}, [showLocationForm, setModalSize]);

	const renderJobForm = () => (
		<Form
			onSubmit={handleSubmit}
			initialValues={formData}
			render={() => (
				<FormElement>
					<fieldset className="k-form-fieldset">
						<div className={styles.formContainer}>
							<div>
								<Field
									id="jobType"
									dataItemKey="id"
									label="Job Type"
									textField="name"
									name="jobType"
									component={CelerumFormDropDownList}
									onChange={(event) => {
										handleInputChange(event, "jobType", setFormData);
									}}
									data={jobTypes}
									validator={requiredValidator}
									required
								/>
								<Field
									id="customer"
									dataItemKey="id"
									label="Customer"
									textField="name"
									name="customer"
									loading={loadingCustomers}
									component={CelerumSuggestionsInput}
									data={customers}
									handleSearchChange={handleSearchCustomersChange}
									required
									onChange={(event) => {
										setCustomerCurrency(event?.target?.value?.currency);
										handleInputChange(event, "customer", setFormData);
									}}
									validator={requiredValidator}
								/>
								<Field
									id="purchaseOrderNumber"
									label="Purchase Order Number"
									name="purchaseOrderNumber"
									maxLength={250}
									component={CelerumFormInput}
									required
									validator={requiredValidator}
									onChange={(event) => {
										return handleInputChange(
											event,
											"purchaseOrderNumber",
											setFormData,
										);
									}}
									focused="true"
								/>
								<Field
									id="startLocation"
									dataItemKey="id"
									label="Start Location"
									textField="nameAndAddress"
									name="startLocation"
									component={CelerumSuggestionsInput}
									data={locations}
									handleSearchChange={handleSearchLocationsChange}
									onChange={(event) => {
										handleInputChange(event, "startLocation", setFormData);
									}}
									validator={requiredValidator}
									onFooterClick={() => {
										setShowLocationForm(true);
										setFieldToUpdate("startLocation");
									}}
									required
									footer={<CelerumAddLocationLabel />}
								/>
								<Field
									id="startDate"
									name="startDate"
									label="Start Date"
									component={CelerumFormDateTimePicker}
									validator={requiredValidator}
									required
									onChange={(event) => {
										setStartDate(event.target.value);
										handleInputChange(event, "startDate", setFormData);
									}}
								/>
								{formData?.jobType.isContainer && (
									<>
										<Field
											id="containerNumber"
											name="containerNumber"
											onChange={(event) =>
												handleInputChange(event, "containerNumber", setFormData)
											}
											label="Container Number"
											maxLength={250}
											component={CelerumFormInput}
											type="text"
										/>
										<Field
											id="containerPin"
											name="containerPin"
											onChange={(event) =>
												handleInputChange(event, "containerPin", setFormData)
											}
											label="Container Pin"
											maxLength={250}
											component={CelerumFormInput}
											type="text"
										/>
										<Field
											id="containerSealNumber"
											name="containerSealNumber"
											onChange={(event) =>
												handleInputChange(
													event,
													"containerSealNumber",
													setFormData,
												)
											}
											label="Container Seal Number"
											maxLength={250}
											component={CelerumFormInput}
											type="text"
										/>
									</>
								)}
							</div>
							<div>
								<Field
									id="endLocation"
									dataItemKey="id"
									label="End Location"
									textField="nameAndAddress"
									name="endLocation"
									data={locations}
									handleSearchChange={handleSearchLocationsChange}
									validator={requiredValidator}
									onChange={(event) => {
										handleInputChange(event, "endLocation", setFormData);
									}}
									onFooterClick={() => {
										setShowLocationForm(true);
										setFieldToUpdate("endLocation");
									}}
									component={CelerumSuggestionsInput}
									required
									footer={<CelerumAddLocationLabel />}
								/>
								<Field
									id="endDate"
									name="endDate"
									label="End Date"
									min={startDate}
									component={CelerumFormDateTimePicker}
									onChange={(event) => {
										handleInputChange(event, "endDate", setFormData);
									}}
									validator={requiredValidator}
									required
								/>
								<Field
									id="price"
									name="price"
									label="Price"
									component={CelerumFormInputWithIcon}
									onChange={(event) =>
										handleInputChange(event, "price", setFormData)
									}
									maxLength={250}
									placeholder="0.00"
								>
									<SuffixComponent
										value={
											currency?.code
												? getCurrencySymbol(currency.code)
												: getCurrencySymbol("GBP")
										}
									/>
								</Field>
								<Field
									label="Assigned To"
									id="assignedUser"
									name="assignedUser"
									textField="fullName"
									dataItemKey="id"
									component={CelerumFormDropDownList}
									onChange={(event) =>
										handleInputChange(event, "assignedToUser", setFormData)
									}
									data={users}
									validator={requiredValidator}
									required
								/>
								<Field
									id="notes"
									label="Notes"
									name="notes"
									component={CelerumFormTextArea}
									onChange={(event) =>
										handleInputChange(event, "notes", setFormData)
									}
									type="text"
									defaultValue={""}
									maxLength={500}
								/>
							</div>
						</div>
					</fieldset>
					<CelerumSubmitButton type="submit" title="Duplicate Job" />
				</FormElement>
			)}
		/>
	);

	const renderLocationForm = () => (
		<LocationForm<IJobDetails>
			onClose={() => {
				setShowLocationForm(false);
			}}
			addLocationFromAnotherForm
			fieldToSet={fieldToUpdate}
			setFormData={setFormData}
		/>
	);

	return !showLocationForm ? renderJobForm() : renderLocationForm();
};
