import type { Page } from "@progress/kendo-react-dropdowns";
import type { GridPageChangeEvent } from "@progress/kendo-react-grid";
import NProgress from "nprogress";
import { useEffect, useMemo, useState } from "react";
import {
	addAttachmentsAction,
	deleteAttachmentAction,
	fetchAttachmentUriAction,
} from "../../../../../attachments/data-access/src/lib/attachments.slice";
import { TRUCK_GRID_COLUMNS } from "../../../../../common/models/src/lib/constants/grid-column.constants";
import { TRUCKS_PAGE_SIZE } from "../../../../../common/models/src/lib/constants/truck.constants";
import { AttachmentUsage } from "../../../../../common/models/src/lib/enums/attachment.enum";
import { FilterItemType } from "../../../../../common/models/src/lib/enums/filter-item-type.enum";
import {
	ModalSize,
	ModalType,
} from "../../../../../common/models/src/lib/enums/modal.enums";
import type { IEntityAttachmentDto } from "../../../../../common/models/src/lib/interfaces/attachment.interface";
import type { IDocument } from "../../../../../common/models/src/lib/interfaces/document.interface";
import type {
	IDateFilter,
	IFilterItem,
} from "../../../../../common/models/src/lib/interfaces/filter.interface";
import type { ITruckRequestDto } from "../../../../../common/models/src/lib/interfaces/truck.interface";
import {
	useAppDispatch,
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../../../common/stores/src/lib/utils";
import { CelerumConfirmModal } from "../../../../../common/ui/src/lib/components/celerum-confirm-modal/celerum-confirm-modal.component";
import { CelerumDocumentsForm } from "../../../../../common/ui/src/lib/components/celerum-documents-form/celerum-documents-form.component";
import { CelerumFilters } from "../../../../../common/ui/src/lib/components/celerum-filters/celerum-filters.component";
import { CelerumGridHeader } from "../../../../../common/ui/src/lib/components/celerum-grid-header/celerum-grid-header.component";
import { CelerumGrid } from "../../../../../common/ui/src/lib/components/celerum-grid/celerum-grid.component";
import { CelerumModal } from "../../../../../common/ui/src/lib/components/celerum-modal/celerum-modal.component";
import { buildFilterQueryString } from "../../../../../common/utils/src/lib/helpers/query.helpers";
import { useOldLocalStorage } from "../../../../../common/utils/src/lib/hooks/use-local-storage.hook";
import { fetchConstraintsAction } from "../../../../../constraints/data-access/src/lib/constraints.slice";
import { fetchTruckTypesAction } from "../../../../data-access/src/lib/truck-types.slice";
import {
	attachDocumentsToTruckAction,
	clearTrucksAction,
	deleteDocumentsFromTruckAction,
	deleteTruckAction,
	fetchTrucksAction,
} from "../../../../data-access/src/lib/trucks.slice";
import { TruckForm } from "../components/truck-form/truck-form.component";

interface TruckFilterState {
	nextMOTDateFilter: IDateFilter[];
	nextInspectionDateFilter: IDateFilter[];
	typesFilter: IFilterItem[];
	constraintsFilter: IFilterItem[];
}

const initialFilterState: TruckFilterState = {
	nextMOTDateFilter: [],
	nextInspectionDateFilter: [],
	typesFilter: [],
	constraintsFilter: [],
};

export const TrucksFeature = () => {
	const dispatch = useAppDispatch();
	const dispatchWithNotifications = useAppDispatchWithNotifications();

	const {
		trucks: { data, total, loading },
		truckTypes,
		constraints,
		currentBusinessUnit,
	} = useAppSelector((state) => ({
		trucks: state.trucks,
		truckTypes: state.truckTypes.data,
		constraints: state.constraints.data,
		currentBusinessUnit: state.authentication.currentBusinessUnit,
	}));

	const [showModal, setShowModal] = useState<{
		createOrUpdate: boolean;
		delete: boolean;
		documents: boolean;
	}>({
		createOrUpdate: false,
		delete: false,
		documents: false,
	});
	const [selectedTruck, setSelectedTruck] = useState<
		ITruckRequestDto | undefined
	>(undefined);
	const [searchFilter, setSearchFilter] = useState<string>("");
	const [sort, setSort] = useState<string>("");
	const [page, setPage] = useState<Page>({ skip: 0, take: TRUCKS_PAGE_SIZE });
	const [filters, setFilters] = useState<string>("");

	const [truckFilters, setTruckFilters] = useOldLocalStorage<TruckFilterState>(
		"truckFilterState",
		initialFilterState,
	);

	const openCreateModal = () => {
		setShowModal({ ...showModal, createOrUpdate: true });
	};

	const openUpdateModal = (truck: ITruckRequestDto) => {
		setShowModal({ ...showModal, createOrUpdate: true });
		setSelectedTruck({
			...truck,
			businessUnitName: currentBusinessUnit?.name,
			nextInspectionTime: new Date(truck.nextInspectionTime),
			nextMOTDate: new Date(truck.nextMOTDate),
		});
	};

	const openDeleteModal = (truck: ITruckRequestDto) => {
		setShowModal({ ...showModal, delete: true });
		setSelectedTruck(truck);
	};

	const closeModal = () => {
		setShowModal({ createOrUpdate: false, delete: false, documents: false });
		setSelectedTruck(undefined);
	};

	const handleDeleteTruck = () => {
		if (!selectedTruck) return;

		dispatchWithNotifications({
			action: deleteTruckAction,
			payload: selectedTruck.id,
			successMessage: `Truck named ${selectedTruck.name} was deleted successfully.`,
			errorMessage: `Could not delete truck named ${selectedTruck.name}.`,
		});
		setSelectedTruck(undefined);
	};

	const openDocumentsModal = (item: ITruckRequestDto) => {
		setSelectedTruck(item);
		setShowModal({ ...showModal, documents: true });
	};

	const handleAddAttachmentsForTruck = async (
		formState: IEntityAttachmentDto,
	) => {
		try {
			const actionResult = await dispatchWithNotifications({
				action: addAttachmentsAction,
				payload: formState,
				successMessage: "Attachments were successfully inserted.",
				errorMessage: "Could not insert attachment.",
			});
			if (addAttachmentsAction.fulfilled.match(actionResult)) {
				dispatch(
					attachDocumentsToTruckAction({
						documents: actionResult.payload,
						entityId: formState.entityId,
					}),
				);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleDeleteDocumentFromTruck = async (
		attachmentId: number | string,
	) => {
		try {
			const actionResult = await dispatchWithNotifications({
				action: deleteAttachmentAction,
				payload: attachmentId,
				successMessage: "Attachment was successfully deleted.",
				errorMessage: "Could not delete attachment.",
			});
			if (deleteAttachmentAction.fulfilled.match(actionResult)) {
				dispatch(deleteDocumentsFromTruckAction(Number(attachmentId)));
				selectedTruck &&
					setSelectedTruck({
						...selectedTruck,
						documents: selectedTruck.documents.filter(
							(document: IDocument) => document.id !== attachmentId,
						),
					});
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleDownloadAttachment = (attachmentId: number | string) => {
		dispatch(fetchAttachmentUriAction(attachmentId));
	};

	const renderedTrucks = useMemo(
		() =>
			data.map((truck) => ({
				...truck,
				nextInspectionTime: new Date(truck.nextInspectionTime),
				nextMOTDate: new Date(truck.nextMOTDate),
			})),
		[data],
	);

	const clearFilters = () => {
		setTruckFilters(initialFilterState);
	};

	const requestDataIfNeeded = (event: GridPageChangeEvent) => {
		const { skip, take } = event.page;
		for (let i = skip; i < skip + take && i < data.length; i++) {
			/** if there is a row with ID -1, it means that we need to fetch more data. */
			if (data[i]?.id === -1) {
				if (loading) {
					return;
				}

				const page = Math.ceil(skip / TRUCKS_PAGE_SIZE) + 1;
				dispatchWithNotifications({
					action: fetchTrucksAction,
					payload: { page, pageSize: TRUCKS_PAGE_SIZE, sort, filters },
					errorMessage: "Could not fetch trucks.",
				});
				break;
			}
		}
	};

	const requestSortedData = (sort: string) => {
		setSort(sort);
		/** Always clear existing data and start fetching again
		 * from page 1.
		 */
		dispatch(clearTrucksAction());
		dispatch(
			fetchTrucksAction({ page: 1, pageSize: TRUCKS_PAGE_SIZE, sort, filters }),
		);
	};

	useEffect(() => {
		const typesFilterLists = [];

		const {
			nextInspectionDateFilter,
			nextMOTDateFilter,
			typesFilter,
			constraintsFilter,
		} = truckFilters;

		typesFilter.length && typesFilterLists.push(typesFilter);

		const combinedDateFilters = [
			...nextInspectionDateFilter,
			...nextMOTDateFilter,
		];

		const filters = buildFilterQueryString(
			searchFilter,
			["name", "notes", "registration", "truckType.name", "subcontractor.name"],
			combinedDateFilters,
			typesFilterLists,
			constraintsFilter,
		);

		setFilters(filters);

		const hasFilters =
			searchFilter ||
			combinedDateFilters.length ||
			typesFilterLists.length ||
			constraintsFilter;

		const payload = {
			page: 1,
			pageSize: TRUCKS_PAGE_SIZE,
			sort,
			filters: hasFilters ? filters : "",
		};

		dispatchWithNotifications({
			action: fetchTrucksAction,
			payload,
			errorMessage: "Could not fetch trucks.",
		});

		setPage({ skip: 0, take: TRUCKS_PAGE_SIZE });
	}, [truckFilters, searchFilter, sort, dispatchWithNotifications]);

	useEffect(() => {
		if (loading) {
			NProgress.start();
		} else {
			NProgress.done();
		}
	}, [loading]);

	useEffect(() => {
		dispatchWithNotifications({
			action: fetchTruckTypesAction,
			payload: {},
			errorMessage: "Could not fetch truck types.",
		});
		dispatchWithNotifications({
			action: fetchConstraintsAction,
			payload: {},
			errorMessage: "Could not fetch constraints.",
		});

		return () => {
			dispatch(clearTrucksAction());
		};
	}, [dispatch, dispatchWithNotifications]);

	return (
		<>
			<CelerumGridHeader
				title="Trucks"
				numberOfItems={total}
				addButtonName="Add Truck"
				addButtonDisabled={loading}
				handleOpenAddModal={openCreateModal}
			>
				<CelerumFilters
					setFilters={setTruckFilters}
					setSearchFilter={setSearchFilter}
					initialValues={{
						typesFilter: truckFilters.typesFilter,
						constraintsFilter: truckFilters.constraintsFilter,
						nextMOTDateFilter: truckFilters.nextMOTDateFilter,
						nextInspectionDateFilter: truckFilters.nextInspectionDateFilter,
					}}
					filters={{
						typesFilter: {
							name: "Types",
							column: "truckType.id",
							values: truckTypes,
							type: FilterItemType.DROPDOWN,
						},
						constraintsFilter: {
							name: "Constraints",
							column: "constraintIds",
							values: constraints,
							type: FilterItemType.DROPDOWN,
						},
						nextMOTDateFilter: {
							name: "Next MOT Date",
							column: "nextMOTDate",
							type: FilterItemType.DATERANGE,
						},
						nextInspectionDateFilter: {
							name: "Next Inspection Date",
							column: "nextInspectionDate",
							type: FilterItemType.DATERANGE,
						},
					}}
					clearFilters={clearFilters}
				/>
			</CelerumGridHeader>
			<CelerumGrid
				columns={TRUCK_GRID_COLUMNS}
				data={renderedTrucks}
				total={total}
				page={page}
				setPage={setPage}
				handleUpdate={openUpdateModal}
				handleDelete={openDeleteModal}
				openDocumentsModal={openDocumentsModal}
				requestSortedData={requestSortedData}
				requestDataIfNeeded={requestDataIfNeeded}
			/>
			<CelerumModal
				title={selectedTruck ? selectedTruck.name : "Add New Truck"}
				width={ModalSize.Medium}
				toggleDialog={closeModal}
				visible={showModal.createOrUpdate}
			>
				<TruckForm formState={selectedTruck} onClose={closeModal} />
			</CelerumModal>
			<CelerumConfirmModal
				type={ModalType.Delete}
				subtitle="The truck will be completely removed and cannot be recovered."
				entityName="truck"
				entityProperty="named"
				entityValue={selectedTruck?.name}
				isOpen={showModal.delete}
				handleSubmit={handleDeleteTruck}
				handleClose={closeModal}
			/>
			<CelerumModal
				title={selectedTruck?.name || "Add new documents"}
				width={ModalSize.Small}
				toggleDialog={closeModal}
				visible={showModal.documents}
			>
				<CelerumDocumentsForm
					documents={selectedTruck?.documents || []}
					onClose={closeModal}
					entityId={selectedTruck?.id}
					entityUsage={AttachmentUsage.Truck}
					handleAddAttachments={handleAddAttachmentsForTruck}
					handleDeleteAttachment={handleDeleteDocumentFromTruck}
					handleDownloadAttachment={handleDownloadAttachment}
				/>
			</CelerumModal>
		</>
	);
};
