import { Reveal } from "@progress/kendo-react-animation";
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import {
	Card,
	CardBody,
	ExpansionPanel,
	ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import { useQuery } from "@tanstack/react-query";
import {
	APIProvider,
	AdvancedMarker,
	AdvancedMarkerAnchorPoint,
	Map as GMap,
} from "@vis.gl/react-google-maps";
import dayjs from "dayjs";
import { groupBy, isNotNil } from "es-toolkit";
import { useMemo, useState } from "react";
import { environment } from "../../libs/environments/src/environment/environment";
import { GLOBALS } from "../_GLOBALS";
import type {
	ClosestDriversRecommendation,
	ViewLocationResponse,
} from "../api/JobApi";
import { DFlex } from "../display/DFlex";
import { DGrid } from "../display/DGrid";
import type { Leg } from "../forms/LegForm";
import { jobApi, usRecommendationSettings } from "../helpers";
import { useDialog } from "../helpersReact";
import styles from "./useRecommendationsDialog.module.css";

type ClosestDriverRecommendationsMapProps = {
	data: ClosestDriversRecommendation[];
	locationData: ViewLocationResponse | undefined;
	onRecommendationSelected?: (recommendation: Recommendation) => void;
};
const ClosestDriverRecommendationsMap = ({
	data,
	locationData,
	onRecommendationSelected,
}: ClosestDriverRecommendationsMapProps) => {
	const groupedByLocation = useMemo(
		() =>
			Object.values(
				groupBy(
					data.map((x, i) => ({ ...x, index: i })),
					(x) => `${x.latitude},${x.longitude}`,
				),
			),
		[data],
	);
	const bounds = useMemo(() => {
		if (!locationData) {
			return undefined;
		}
		const latitudes = [
			locationData.latitude,
			...data.map((x) => x.latitude),
		].filter(isNotNil);
		const longitudes = [
			locationData.longitude,

			...data.map((x) => x.longitude),
		].filter(isNotNil);
		const minLat = Math.min(...latitudes);
		const maxLat = Math.max(...latitudes);
		const minLng = Math.min(...longitudes);
		const maxLng = Math.max(...longitudes);
		return {
			north: maxLat,
			south: minLat,
			east: maxLng,
			west: minLng,
		};
	}, [locationData, data]);
	return (
		<APIProvider apiKey={environment.googleMapsApiKey}>
			<GMap
				mapId="ClosestDriverRecommendationsMap"
				style={{ height: "300px" }}
				defaultBounds={bounds}
				clickableIcons={false}
				disableDoubleClickZoom
				disableDefaultUI
			>
				{locationData && (
					<AdvancedMarker
						position={{
							lat: locationData.latitude ?? 0,
							lng: locationData.longitude ?? 0,
						}}
						anchorPoint={AdvancedMarkerAnchorPoint.CENTER}
					>
						<div
							style={{ backgroundColor: "white", padding: 5, zIndex: 999999 }}
						>
							<b>📦 {locationData.name}</b>
							<br />
							{locationData.address}
						</div>
					</AdvancedMarker>
				)}
				{groupedByLocation.map((x) => {
					const first = x[0];
					return (
						first && (
							<AdvancedMarker
								key={first.driverId}
								position={{ lat: first.latitude, lng: first.longitude }}
								anchorPoint={AdvancedMarkerAnchorPoint.CENTER}
								clickable
							>
								<div
									className={styles.ghosted}
									style={{ backgroundColor: "white", padding: 5 }}
								>
									<DFlex column gap="3px">
										{x.map((x) => (
											<DFlex key={x.driverId} spaceBetween vCenter>
												<b>
													{x.index + 1}
													{") "}
													{x.driverName}
												</b>
												<Button
													size="small"
													themeColor="primary"
													onClick={() =>
														onRecommendationSelected?.({
															...x,
															index: x.index,
															type: "ClosestDrivers",
														})
													}
												>
													Select
												</Button>
											</DFlex>
										))}
									</DFlex>
									<br />
									{first.distance.toFixed(2)}km away
								</div>
							</AdvancedMarker>
						)
					);
				})}
			</GMap>
		</APIProvider>
	);
};

type ClosestDriverRecommendationsProps = {
	locationId: number | undefined;
	onRecommendationSelected?: (recommendation: Recommendation) => void;
};
const ClosestDriverRecommendations = ({
	locationId,
	onRecommendationSelected,
}: ClosestDriverRecommendationsProps) => {
	const [expanded, setExpanded] = useState(false);
	const data = useQuery({
		queryKey: ["jobApi.bff.bffClosestDriversRecommendationCreate", locationId],
		queryFn: () => {
			if (!locationId) {
				return [];
			}
			return jobApi.bff
				.bffClosestDriversRecommendationCreate({ locationId })
				.then((x) => x.data);
		},
		initialData: [],
	});
	const locationData = useQuery({
		queryKey: ["jobApi.bff.bffLocationRead", locationId],
		queryFn: () =>
			locationId
				? jobApi.location.locationDetail(locationId).then((x) => x.data)
				: undefined,
	});
	GLOBALS.recommendationsClosestDrivers = data.data;
	return (
		<ExpansionPanel
			expanded={expanded}
			title={
				<>
					Closest Drivers
					{data.isFetching && (
						<Loader size="small" type="infinite-spinner" themeColor="success" />
					)}
				</>
			}
			onAction={() => setExpanded((x) => !x)}
			style={{ minWidth: 600 }}
		>
			<Reveal>
				{expanded && (
					<ExpansionPanelContent>
						<DGrid>
							{data.isFetchedAfterMount ? (
								<ClosestDriverRecommendationsMap
									data={data.data}
									locationData={locationData.data}
									onRecommendationSelected={onRecommendationSelected}
								/>
							) : (
								"Loading map..."
							)}
							{data.data.map((x, index) => (
								<Card key={x.driverId}>
									<CardBody>
										<DFlex spaceBetween>
											<div>Driver:</div>
											<b>{x.driverName}</b>
										</DFlex>
										<DFlex spaceBetween>
											<div>Truck:</div>
											<b>{x.truckName ?? "-"}</b>
										</DFlex>
										<DFlex spaceBetween>
											<div>Trailer:</div>
											<b>{x.trailerName ?? "-"}</b>
										</DFlex>
										<DFlex spaceBetween>
											<div>Distance:</div>
											<b>{x.distance.toFixed(2)} km</b>
										</DFlex>
										<DFlex spaceBetween>
											<div>Latest known location date:</div>
											<b>
												{dayjs(x.latestKnownLocationDate).format(
													"YYYY-MM-DD HH:mm",
												)}
											</b>
										</DFlex>
										<DFlex flexEnd>
											<Button
												themeColor="primary"
												onClick={() =>
													onRecommendationSelected?.({
														...x,
														index,
														type: "ClosestDrivers",
													})
												}
											>
												Select
											</Button>
										</DFlex>
									</CardBody>
								</Card>
							))}
						</DGrid>
					</ExpansionPanelContent>
				)}
			</Reveal>
		</ExpansionPanel>
	);
};

type RandomDriverRecommendationsProps = {
	onRecommendationSelected?: (recommendation: Recommendation) => void;
};
const RandomDriverRecommendations = ({
	onRecommendationSelected,
}: RandomDriverRecommendationsProps) => {
	const [expanded, setExpanded] = useState(false);
	const data = useQuery({
		queryKey: ["jobApi.bff.bffRandomDriversRecommendationCreate"],
		queryFn: () =>
			jobApi.bff.bffRandomDriversRecommendationCreate().then((x) => x.data),
		initialData: [],
	});
	GLOBALS.recommendationsRandomDrivers = data.data;
	return (
		<ExpansionPanel
			expanded={expanded}
			title={
				<>
					Random Drivers
					{data.isFetching && (
						<Loader size="small" type="infinite-spinner" themeColor="success" />
					)}
				</>
			}
			onAction={() => setExpanded((x) => !x)}
			style={{ minWidth: 600 }}
		>
			<Reveal>
				{expanded && (
					<ExpansionPanelContent>
						<DGrid>
							{data.data.map((x, index) => (
								<Card key={x.driverId}>
									<CardBody>
										<DFlex spaceBetween>
											<div>Driver:</div>
											<b>{x.driverName}</b>
										</DFlex>
										<DFlex spaceBetween>
											<div>Truck:</div>
											<b>{x.truckName ?? "-"}</b>
										</DFlex>
										<DFlex spaceBetween>
											<div>Trailer:</div>
											<b>{x.trailerName ?? "-"}</b>
										</DFlex>
										<DFlex flexEnd>
											<Button
												themeColor="primary"
												onClick={() =>
													onRecommendationSelected?.({
														...x,
														index,
														type: "RandomDrivers",
													})
												}
											>
												Select
											</Button>
										</DFlex>
									</CardBody>
								</Card>
							))}
						</DGrid>
					</ExpansionPanelContent>
				)}
			</Reveal>
		</ExpansionPanel>
	);
};

type RecommendationsProps = {
	settings: string[];
	formData: Leg | undefined;
	onRecommendationSelected?: (recommendation: Recommendation) => void;
};
const Recommendations = ({
	settings,
	formData,
	onRecommendationSelected,
}: RecommendationsProps) => {
	const locationId =
		formData?.collectionLocationId ?? formData?.deliveryLocationId ?? undefined;
	return (
		<div>
			{settings.includes("ClosestDrivers") && (
				<div>
					<ClosestDriverRecommendations
						locationId={locationId}
						onRecommendationSelected={onRecommendationSelected}
					/>
				</div>
			)}
			{settings.includes("RandomDrivers") && (
				<div>
					<RandomDriverRecommendations
						onRecommendationSelected={onRecommendationSelected}
					/>
				</div>
			)}
		</div>
	);
};

export type Recommendation = {
	driverId?: number | null;
	truckId?: number | null;
	trailerId?: number | null;
	subcontractorId?: number | null;
	index: number;
	type: string;
};

export const useRecommendationsDialog = (
	formData: Leg | undefined,
	onRecommendationSelected?: (recommendation: Recommendation) => void,
) => {
	const data = usRecommendationSettings();
	const isEnabled = data.data.length > 0;
	const [toggleDialog, dialog] = useDialog(
		isEnabled && (
			<Recommendations
				settings={data.data}
				formData={formData}
				onRecommendationSelected={onRecommendationSelected}
			/>
		),
		"Recommendations",
	);
	return [toggleDialog, dialog, isEnabled] as const;
};
