import { useMemo, useState } from "react";
import {
	addAttachmentsAction,
	fetchAttachmentUriAction,
} from "../../../../../../attachments/data-access/src/lib/attachments.slice";
import { NOT_AVAILABLE } from "../../../../../../common/models/src/lib/constants/messages.constants";
import { AttachmentUsage } from "../../../../../../common/models/src/lib/enums/attachment.enum";
import { ModalSize } from "../../../../../../common/models/src/lib/enums/modal.enums";
import type { IEntityAttachmentDto } from "../../../../../../common/models/src/lib/interfaces/attachment.interface";
import {
	useAppDispatch,
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../../../../common/stores/src/lib/utils";
import { CelerumViewDetailsButton } from "../../../../../../common/ui/src/lib/components/celerum-buttons/celerum-buttons.component";
import { CelerumDocumentsForm } from "../../../../../../common/ui/src/lib/components/celerum-documents-form/celerum-documents-form.component";
import { CelerumLoader } from "../../../../../../common/ui/src/lib/components/celerum-loader/celerum-loader.components";
import { CelerumModal } from "../../../../../../common/ui/src/lib/components/celerum-modal/celerum-modal.component";
import {
	getFormattedCurrency,
	getFormattedValue,
} from "../../../../../../common/utils/src/lib/helpers/currency.helpers";
import { attachDocumentsToJobAction } from "../../../../../../jobs/data-access/src/lib/jobs.slice";
import { canReInvoice } from "../../helpers/invoice-option.helpers";
import styles from "./job-container.module.css";

export const JobContainer = () => {
	const { job, loading } = useAppSelector((state) => state.jobs);
	const dispatchWithNotifications = useAppDispatchWithNotifications();
	const dispatch = useAppDispatch();

	const [showModal, setShowModal] = useState<{
		normalDocuments: boolean;
		invoiceDocuments: boolean;
	}>({
		normalDocuments: false,
		invoiceDocuments: false,
	});

	const renderedJobDetails = useMemo(() => {
		if (job) {
			return {
				...job,
				startDate: new Date(job.startDate),
				endDate: new Date(job.endDate),
				vatRate:
					job.vatRate && job.vatRate !== -1
						? `${getFormattedValue(job.vatRate as number)}%`
						: NOT_AVAILABLE,
			};
		}
	}, [job]);

	const normalDocuments = useMemo(
		() =>
			job?.documents
				? job.documents.filter((document) => !document.isInvoice)
				: [],
		[job],
	);
	const invoiceDocuments = useMemo(
		() =>
			job?.documents
				? job.documents.filter((document) => document.isInvoice)
				: [],
		[job],
	);

	const renderItem = (...args: [string, string | number, Date?]) => {
		return (
			<div className={styles.content__item}>
				<span className={styles.content__item__title}>{args[0]}</span>
				<div className={styles.content__item__wrapper}>
					<span className={styles.content__item__value}>{args[1]}</span>
					{args[2] && (
						<span className={styles.content__item__value}>
							{args[2].toLocaleString()}
						</span>
					)}
				</div>
			</div>
		);
	};

	const handleDownloadAttachment = (attachmentId: number | string) => {
		dispatch(fetchAttachmentUriAction(attachmentId));
	};

	const handleAddAttachments = async (formState: IEntityAttachmentDto) => {
		try {
			const actionResult = await dispatchWithNotifications({
				action: addAttachmentsAction,
				payload: formState,
				successMessage: "Document successfully uploaded.",
				errorMessage: "Could not upload document.",
			});
			if (addAttachmentsAction.fulfilled.match(actionResult)) {
				dispatch(attachDocumentsToJobAction(actionResult.payload));
			}
		} catch (error) {
			console.error(error);
		}
	};

	const isInvoiced = useMemo(() => canReInvoice(job || { status: 1 }), [job]);

	return (
		<>
			<div className={styles.container}>
				<div className={styles.header}>
					<span className={styles.header__title}>Job Information</span>
				</div>
				{loading.job ? (
					<div className={styles.loader}>
						<CelerumLoader visible />
					</div>
				) : (
					<>
						<div className={styles.content}>
							{renderItem(
								"Start Location & Date",
								renderedJobDetails?.startLocation?.name ?? NOT_AVAILABLE,
								renderedJobDetails?.startDate,
							)}
							{renderItem(
								"End Location & Date",
								renderedJobDetails?.endLocation?.name ?? NOT_AVAILABLE,
								renderedJobDetails?.endDate,
							)}
							{renderItem(
								"Job Type",
								renderedJobDetails?.jobType?.name ?? NOT_AVAILABLE,
							)}
							{renderItem(
								"Price",
								renderedJobDetails?.price
									? getFormattedCurrency(
											renderedJobDetails.price,
											renderedJobDetails.customer.currency.code,
										)
									: NOT_AVAILABLE,
							)}
							{renderItem(
								"VAT",
								renderedJobDetails?.vatSum
									? getFormattedCurrency(
											renderedJobDetails.vatSum,
											renderedJobDetails.customer.currency.code,
										)
									: NOT_AVAILABLE,
							)}
							{renderItem(
								"Use VAT Rate",
								renderedJobDetails?.useVat ? "YES" : "NO",
							)}
							{renderItem(
								"VAT Rate",
								renderedJobDetails?.vatRate ?? NOT_AVAILABLE,
							)}
							{renderItem(
								"Operator",
								renderedJobDetails?.assignedUser?.fullName ?? NOT_AVAILABLE,
							)}
							{renderItem(
								"Purchase Order Number",
								renderedJobDetails?.purchaseOrderNumber ?? NOT_AVAILABLE,
							)}
							{renderItem(
								"Total Cost of Legs",
								renderedJobDetails?.cost
									? getFormattedCurrency(
											renderedJobDetails.cost,
											renderedJobDetails.customer.currency.code,
										)
									: NOT_AVAILABLE,
							)}
							{renderItem(
								"Profit",
								renderedJobDetails?.profitPercent
									? `${renderedJobDetails.profitPercent.toFixed(2)}%`
									: NOT_AVAILABLE,
							)}
							{job?.jobType?.isContainer && (
								<>
									{renderItem(
										"Container Number",
										renderedJobDetails?.containerNumber ?? NOT_AVAILABLE,
									)}
									{renderItem(
										"Container Pin",
										renderedJobDetails?.containerPin ?? NOT_AVAILABLE,
									)}
									{renderItem(
										"Container Seal Number",
										renderedJobDetails?.containerSealNumber ?? NOT_AVAILABLE,
									)}
								</>
							)}
							<div className={styles.content__item}>
								<span className={styles.content__item__title}>
									Associated Loads
								</span>
								<div className={styles.content__item__wrapper}>
									{renderedJobDetails?.associatedLoads?.length ? (
										renderedJobDetails.associatedLoads.map((load) => (
											<span
												key={load.id}
												className={styles.content__item__value}
											>
												{load.uniqueId}
												{", "}
											</span>
										))
									) : (
										<span className={styles.content__item__value}>
											{NOT_AVAILABLE}
										</span>
									)}
								</div>
							</div>
						</div>

						<div className={styles.footer}>
							<span className={styles.description}>
								{renderedJobDetails?.notes ?? NOT_AVAILABLE}
							</span>
						</div>

						<div className={styles.buttons}>
							{normalDocuments.length ? (
								<CelerumViewDetailsButton
									title={`View Documents (${normalDocuments.length})`}
									onClick={() =>
										setShowModal({ ...showModal, normalDocuments: true })
									}
								/>
							) : null}
							{invoiceDocuments.length || isInvoiced ? (
								<CelerumViewDetailsButton
									title={`View Invoices (${invoiceDocuments.length})`}
									onClick={() =>
										setShowModal({ ...showModal, invoiceDocuments: true })
									}
								/>
							) : null}
						</div>
					</>
				)}
			</div>

			<CelerumModal
				title="Documents"
				visible={showModal.normalDocuments}
				width={ModalSize.Small}
				toggleDialog={() =>
					setShowModal({ ...showModal, normalDocuments: false })
				}
			>
				<CelerumDocumentsForm
					entityId={job?.id}
					onClose={() => setShowModal({ ...showModal, normalDocuments: false })}
					documents={normalDocuments}
					handleDownloadAttachment={handleDownloadAttachment}
					isInteractive={false}
				/>
			</CelerumModal>

			<CelerumModal
				title="Invoices"
				visible={showModal.invoiceDocuments}
				width={ModalSize.Small}
				toggleDialog={() =>
					setShowModal({ ...showModal, invoiceDocuments: false })
				}
			>
				<CelerumDocumentsForm
					entityId={job?.id}
					entityUsage={AttachmentUsage.Job}
					onClose={() =>
						setShowModal({ ...showModal, invoiceDocuments: false })
					}
					documents={invoiceDocuments}
					handleAddAttachments={handleAddAttachments}
					handleDownloadAttachment={handleDownloadAttachment}
					isInteractive={!isInvoiced}
					isInvoiced={isInvoiced}
				/>
			</CelerumModal>
		</>
	);
};
