import {
	Field,
	Form,
	FormElement,
	type FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { useMemo, useState } from "react";
import { InvoiceSentMethod } from "../../../../../../new/helpers";
import { CUSTOMER_STATUSES } from "../../../../../common/models/src/lib/constants/customers.constants";
import {
	INVOICE_FREQUENCY,
	INVOICE_SEND_METHOD,
	INVOICE_TYPE,
	RADIO_BUTTON,
} from "../../../../../common/models/src/lib/constants/invoice.constants";
import { InvoiceTypeMethod } from "../../../../../common/models/src/lib/enums/invoice.enum";
import type { ICustomer } from "../../../../../common/models/src/lib/interfaces/customer.interface";
import {
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../../../common/stores/src/lib/utils";
import { CelerumAddItemsInInput } from "../../../../../common/ui/src/lib/components/celerum-add-items-in-input/celerum-add-items-in-inputs.component";
import { CelerumSubmitButton } from "../../../../../common/ui/src/lib/components/celerum-buttons/celerum-buttons.component";
import {
	CelerumDisabledInput,
	CelerumFormDropDownList,
	CelerumFormInput,
	CelerumFormTextArea,
} from "../../../../../common/ui/src/lib/components/celerum-form-elements/celerum-form-elements.component";
import { useInputChange } from "../../../../../common/utils/src/lib/hooks/use-input-change.hooks";
import { requiredValidator } from "../../../../../common/utils/src/lib/validators/validators";
import {
	createCustomerAction,
	updateCustomerAction,
} from "../../../../data-access/src/lib/customers.slice";
import styles from "./customer-form.module.css";

interface CustomerFormProps {
	syncedWithSage: boolean;
	formState?: ICustomer;
	closeModal: () => void;
}

export const CustomerForm = ({
	syncedWithSage,
	formState,
	closeModal,
}: CustomerFormProps) => {
	const dispatchWithNotifications = useAppDispatchWithNotifications();
	const { checklists, templates } = useAppSelector((state) => state.invoices);

	const [isFormModified, setIsFormModified] = useState<boolean>(false);
	const [phones, setPhones] = useState<string[]>(formState?.phones || []);
	const [emails, setEmails] = useState<string[]>(formState?.emails || []);

	const [customer, setFormData] = useState<ICustomer | undefined>({
		...formState,
		includePodAttachmentToInvoiceEmail:
			formState?.includePodAttachmentToInvoiceEmail ? "Yes" : "No",
		isPurchaseOrderNumberMandatory: formState?.isPurchaseOrderNumberMandatory
			? "Yes"
			: "No",
		isEuCustomer: formState?.isEuCustomer ? "Yes" : "No",
		mergeAttachmentInOneFile: formState?.mergeAttachmentInOneFile
			? "Yes"
			: "No",
	} as ICustomer);

	const { currencies, currentBusinessUnit, loading } = useAppSelector(
		(state) => ({
			currencies: state.currencies.data,
			currentBusinessUnit: state.authentication.currentBusinessUnit,
			loading: state.customers.loading,
		}),
	);

	const codes = currencies.map((currency) => currency.code);

	const renderTemplates = useMemo(() => {
		return templates.map((item) => item.path);
	}, [templates]);

	const handleInputChange = useInputChange();

	const handleSubmit = async (event: FormSubmitClickEvent) => {
		const {
			id,
			name,
			accountCode,
			vatNumber,
			currencyCode,
			invoiceFrequency,
			invoiceType,
			invoiceSentMethod,
			status,
			address,
			checklist,
			invoiceTemplate,
		} = event.values;

		if (!event.isValid) {
			return;
		}

		const requestObject = {
			id,
			name,
			accountCode,
			vatNumber,
			status: status?.id,
			currencyId: currencies.find((currency) => currency.code === currencyCode)
				?.id as number,
			invoiceFrequency: INVOICE_FREQUENCY.findIndex(
				(frequency) => frequency === invoiceFrequency,
			),
			invoiceType: InvoiceTypeMethod[invoiceType],
			invoiceSentMethod: InvoiceSentMethod[invoiceSentMethod],
			invoiceTemplate: invoiceTemplate,
			checklistId: checklist?.id,
			phones: phones,
			address: address,
			emails: emails,
			includePodAttachmentToInvoiceEmail:
				customer?.includePodAttachmentToInvoiceEmail === "Yes",
			isPurchaseOrderNumberMandatory:
				customer?.isPurchaseOrderNumberMandatory === "Yes",
			isEuCustomer: customer?.isEuCustomer === "Yes",
			mergeAttachmentInOneFile: customer?.mergeAttachmentInOneFile === "Yes",
		};

		if (formState) {
			const actionResult = await dispatchWithNotifications({
				action: updateCustomerAction,
				payload: requestObject,
				successMessage: `Customer ${requestObject.name} was successfully updated.`,
				errorMessage: `Could not update customer ${requestObject.name}.`,
			});

			updateCustomerAction.fulfilled.match(actionResult) && closeModal();
		} else {
			const actionResult = await dispatchWithNotifications({
				action: createCustomerAction,
				payload: requestObject,
				successMessage: `Customer ${requestObject.name} was successfully created.`,
				errorMessage: `Could not create customer ${requestObject.name}.`,
			});

			createCustomerAction.fulfilled.match(actionResult) && closeModal();
		}
	};

	const renderedFormState = {
		...formState,
		status: CUSTOMER_STATUSES.find((status) => status.id === formState?.status),
		includePodAttachmentToInvoiceEmail:
			formState?.includePodAttachmentToInvoiceEmail ? "Yes" : "No",
		isPurchaseOrderNumberMandatory: formState?.isPurchaseOrderNumberMandatory
			? "Yes"
			: "No",
		isEuCustomer: formState?.isEuCustomer ? "Yes" : "No",
		mergeAttachmentInOneFile: formState?.mergeAttachmentInOneFile
			? "Yes"
			: "No",
	};

	return (
		<Form
			onSubmitClick={handleSubmit}
			initialValues={renderedFormState}
			render={(formRenderProps) => (
				<FormElement>
					<fieldset className="k-form-fieldset">
						<div className={styles.formContainer}>
							<div>
								<CelerumDisabledInput
									value={currentBusinessUnit?.name}
									label="Business Unit"
								/>
								<Field
									id="status"
									name="status"
									label="Status"
									dataItemKey="id"
									textField="name"
									data={CUSTOMER_STATUSES}
									component={CelerumFormDropDownList}
									validator={requiredValidator}
									required
									disabled={syncedWithSage}
								/>
								<Field
									id="name"
									name="name"
									component={CelerumFormInput}
									label="Name"
									type="text"
									maxLength={150}
									validator={requiredValidator}
									required
									focused="true"
									disabled={syncedWithSage}
								/>
								<CelerumAddItemsInInput
									label="Contact Number"
									name="contactNumber"
									id="contact number"
									buttonName="Add another phone number"
									result={phones}
									setResult={setPhones}
									setIsFormModified={setIsFormModified}
									disabled={syncedWithSage}
								/>
								<CelerumAddItemsInInput
									label="Contact Email"
									name="email"
									id="email"
									buttonName="Add another email"
									result={emails}
									setResult={setEmails}
									setIsFormModified={setIsFormModified}
									disabled={syncedWithSage}
								/>
							</div>
							<div>
								<Field
									id="invoiceSentMethod"
									name="invoiceSentMethod"
									label="Invoice Send Method"
									data={INVOICE_SEND_METHOD}
									component={CelerumFormDropDownList}
									validator={requiredValidator}
									required
								/>
								<Field
									id="checklist"
									name="checklist"
									label="Checklist Template"
									dataItemKey="id"
									textField="name"
									data={checklists}
									component={CelerumFormDropDownList}
								/>
								<Field
									id="invoiceTemplate"
									name="invoiceTemplate"
									label="Invoice Template"
									data={renderTemplates}
									component={CelerumFormDropDownList}
								/>
								<Field
									id="invoiceType"
									name="invoiceType"
									label="Invoice Type"
									data={INVOICE_TYPE}
									component={CelerumFormDropDownList}
									validator={requiredValidator}
									required
								/>
								<Field
									id="invoiceFrequency"
									name="invoiceFrequency"
									label="Invoice Frequency"
									data={INVOICE_FREQUENCY}
									component={CelerumFormDropDownList}
									validator={requiredValidator}
									required
								/>
								<Field
									id="includePodAttachmentToInvoiceEmail"
									name="includePodAttachmentToInvoiceEmail"
									label="Attachment POD invoice email?"
									data={RADIO_BUTTON}
									component={CelerumFormDropDownList}
									validator={requiredValidator}
									required
									onChange={(event) => {
										handleInputChange(
											event,
											"includePodAttachmentToInvoiceEmail",
											setFormData,
										);
									}}
								/>
								<Field
									id="mergeAttachmentInOneFile"
									name="mergeAttachmentInOneFile"
									label="Merge attached documents in one file?"
									data={RADIO_BUTTON}
									component={CelerumFormDropDownList}
									validator={requiredValidator}
									required
									onChange={(event) => {
										handleInputChange(
											event,
											"mergeAttachmentInOneFile",
											setFormData,
										);
									}}
								/>
							</div>
							<div>
								<Field
									id="address"
									name="address"
									component={CelerumFormTextArea}
									label="Address"
									type="text"
									maxLength={150}
									disabled={syncedWithSage}
									defaultValue={""}
								/>
								<Field
									id="accountCode"
									name="accountCode"
									component={CelerumFormInput}
									label="Account Code"
									type="text"
									maxLength={8}
									validator={requiredValidator}
									required
									disabled={syncedWithSage}
								/>
								<Field
									id="vatNumber"
									name="vatNumber"
									component={CelerumFormInput}
									label="VAT Number"
									type="text"
									maxLength={50}
									disabled={syncedWithSage}
								/>
								<Field
									id="currencyCode"
									name="currencyCode"
									label="Currency"
									data={codes}
									component={CelerumFormDropDownList}
									validator={requiredValidator}
									required
									disabled={syncedWithSage}
								/>
								<Field
									id="isPurchaseOrderNumberMandatory"
									name="isPurchaseOrderNumberMandatory"
									label="Is PO number mandatory?"
									data={RADIO_BUTTON}
									component={CelerumFormDropDownList}
									validator={requiredValidator}
									required
									onChange={(event) => {
										handleInputChange(
											event,
											"isPurchaseOrderNumberMandatory",
											setFormData,
										);
									}}
								/>
								<Field
									id="isEuCustomer"
									name="isEuCustomer"
									label="Is EU customer?"
									data={RADIO_BUTTON}
									component={CelerumFormDropDownList}
									validator={requiredValidator}
									required
									onChange={(event) => {
										handleInputChange(event, "isEuCustomer", setFormData);
									}}
								/>
							</div>
						</div>
					</fieldset>
					<CelerumSubmitButton
						type="submit"
						disabled={
							(!isFormModified && !formRenderProps.allowSubmit) || loading
						}
						title="Submit"
					/>
				</FormElement>
			)}
		/>
	);
};
