import {
	type SortDescriptor,
	toDataSourceRequestString,
} from "@progress/kendo-data-query";
import type { Page } from "@progress/kendo-react-dropdowns";
import {
	Grid,
	GridCell,
	type GridCellProps,
	GridColumn,
	type GridPageChangeEvent,
	type GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import type { MenuSelectEvent } from "@progress/kendo-react-layout";
import cls from "classnames";
import {
	type Dispatch,
	type SetStateAction,
	useCallback,
	useState,
} from "react";
import { DEFAULT_PAGE_SIZE } from "../../../../../models/src/lib/constants/grid.constants";
import { CommandCellType } from "../../../../../models/src/lib/enums/command-cell.enum";
import { LegStatus } from "../../../../../models/src/lib/enums/leg.enum";
import type { MoreOptions } from "../../../../../models/src/lib/interfaces/more-options.interface";
import { handleOptions } from "../../../../../utils/src/lib/helpers/action.helpers";
import styles from "./celerum-grid.module.css";
import { CheckboxCell } from "./components/checkbox-cell/checkbox-cell.component";
import { CommandCell } from "./components/command-cell/command-cell.component";
import { HeaderCell } from "./components/header-cell/header-cell.component";
import { InputtextCell } from "./components/inputtext-cell/inputtext-cell.component";
import { LoadingCell } from "./components/loading-cell/loading-cell.component";
import { ReactiveCell } from "./components/reactive-cell/reactive-cell.component";
import { ReverseEllipsisCell } from "./components/reverse-ellipsis-cell/reverse-ellipsis-cell.component";
import { StatusCell } from "./components/status-cell/status-cell.component";
import { TagsCell } from "./components/tags-cell/tags-cell.component";

interface CelerumGridProps {
	// biome-ignore lint/suspicious/noExplicitAny: no bother
	columns: any[];
	// biome-ignore lint/suspicious/noExplicitAny: no bother
	data: any[];
	total: number;
	page?: Page;
	setPage?: Dispatch<SetStateAction<Page>>;
	commandCellType?: CommandCellType;
	loading?: boolean;
	selectedItemsIds?: Set<number>;
	navigateItem?: string;
	actionField?: string;
	canDelete?: boolean;
	statusMappings?: {
		[key: number]: { title: string; className: string };
	};
	// biome-ignore lint/suspicious/noExplicitAny: no bother
	handleDelete?: (item: any) => void;
	handleMoreOptions?: MoreOptions;
	handleActionSelected?: (action: MenuSelectEvent, id: number) => void;
	handleStatusChange?: (id: number, status: number) => void;
	// biome-ignore lint/suspicious/noExplicitAny: no bother
	handleUpdate?: (item: any) => void;
	handleNavigate?: (id: number | string) => void;
	// biome-ignore lint/suspicious/noExplicitAny: no bother
	openDocumentsModal?: (item: any) => void;
	requestDataIfNeeded?: (event: GridPageChangeEvent) => void;
	requestSortedData?: (sort: string) => void;
	setSelectedItemsIds?: (items: Set<number>) => void;
}

export const CelerumGrid = ({
	columns,
	commandCellType = CommandCellType.EditDelete,
	data,
	total,
	loading = false,
	handleNavigate,
	requestDataIfNeeded,
	handleDelete,
	handleUpdate,
	handleStatusChange,
	requestSortedData,
	handleMoreOptions,
	handleActionSelected,
	openDocumentsModal,
	statusMappings,
	selectedItemsIds,
	setSelectedItemsIds,
	page,
	setPage,
	navigateItem,
	actionField,
	canDelete = true,
}: CelerumGridProps) => {
	const [sortState, setSortState] = useState<Array<SortDescriptor>>([]);

	const setGridColumnFormat = useCallback((type: string) => {
		switch (type) {
			case "date":
				return "{0:dd/MM/yyyy}";
			default:
				return undefined;
		}
	}, []);

	const setGridColumnWidth = useCallback((type: string) => {
		switch (type) {
			case "tags":
				return "280rem";
			case "checkbox":
				return "65px";
			case "reactive":
				return "160rem";
			default:
				return undefined;
		}
	}, []);

	const renderColumns = useCallback(() => {
		return columns.map((column) => {
			let cell: React.ComponentType<GridCellProps> | null = null;

			switch (column.type) {
				case "tags":
					cell = ({ dataItem, field }: GridCellProps) =>
						dataItem && field ? (
							<TagsCell
								items={dataItem[column.showAs || field]}
								loading={loading}
							/>
						) : null;
					break;
				case "status":
					cell = ({ dataItem, field }: GridCellProps) =>
						dataItem && field ? (
							<StatusCell
								loading={loading}
								status={statusMappings?.[dataItem[column.showAs || field]]}
							/>
						) : null;
					break;
				case "reverseEllipsis":
					cell = ({ dataItem, field }: GridCellProps) =>
						dataItem && field ? (
							<ReverseEllipsisCell
								item={dataItem[column.showAs || field]}
								loading={loading}
							/>
						) : null;
					break;
				case "reactive":
					cell = ({ dataItem, field }: GridCellProps) =>
						dataItem && field ? (
							<ReactiveCell
								loading={loading}
								status={dataItem[column.showAs || field]}
								id={dataItem.legId}
								availableStatuses={dataItem.availableStatuses}
								statusMappings={statusMappings}
								isInteractive={
									(!dataItem.transferBusinessUnitId && column.showAs) ||
									dataItem[field] !== LegStatus.PartOfALoad
								}
								handleStatusChange={handleStatusChange}
							/>
						) : null;
					break;
				case "checkbox":
					cell = ({ dataItem, field }: GridCellProps) =>
						dataItem && field ? (
							<CheckboxCell
								canBeChecked={dataItem?.canBeChecked}
								itemId={dataItem.id}
								selectedItemsIds={selectedItemsIds}
								setSelectedItemsIds={setSelectedItemsIds}
							/>
						) : null;
					break;
				case "inputtext":
					cell = ({ dataItem, field }: GridCellProps) =>
						dataItem && field ? (
							<InputtextCell
								value={dataItem[column.showAs || field]}
								onChange={(value) =>
									handleUpdate?.({ ...dataItem, [field]: value })
								}
							/>
						) : null;
					break;
				default:
					break;
			}

			if (!cell && column.showAs) {
				cell = (x: GridCellProps) => (
					<GridCell
						{...x}
						dataItem={{ [column.field]: x.dataItem[column.showAs] }}
					/>
				);
			}

			return (
				<GridColumn
					key={column}
					{...column}
					cell={cell}
					headerCell={(props) => (
						<HeaderCell
							headerProps={props}
							column={column}
							sortState={sortState || []}
						/>
					)}
					headerClassName={styles.gridHeader}
					className={styles.columnContainer}
					format={setGridColumnFormat(column.type)}
					width={setGridColumnWidth(column.type)}
				/>
			);
		});
	}, [
		columns,
		sortState,
		loading,
		selectedItemsIds,
		setGridColumnWidth,
		setSelectedItemsIds,
		setGridColumnFormat,
		handleStatusChange,
		statusMappings,
		handleUpdate,
	]);

	const onPageChange = useCallback(
		(event: GridPageChangeEvent) => {
			if (setPage) {
				setPage(event.page);
				if (requestDataIfNeeded) {
					requestDataIfNeeded(event);
				}
			}
		},
		[requestDataIfNeeded, setPage],
	);

	const onSortChange = useCallback(
		(event: GridSortChangeEvent) => {
			if (setSortState) {
				setSortState(event.sort);
				const sort = toDataSourceRequestString({ sort: event.sort });
				if (requestSortedData) {
					requestSortedData(sort);
				}
			}
		},
		[requestSortedData],
	);

	return (
		<Grid
			/** Need to have the height set via inline styling
			 * for the virtual scrolling to work.
			 */
			style={{ height: "calc(100vh - 116px)" }}
			className={cls(
				styles.gridContainer,
				total === 0 && styles.gridContainerEmpty,
			)}
			rowHeight={60}
			sortable={true}
			scrollable={page && "virtual"}
			data={page ? data.slice(page.skip, page.skip + page.take) : data}
			total={total}
			skip={page?.skip}
			pageSize={page?.take || DEFAULT_PAGE_SIZE}
			sort={sortState}
			onPageChange={page && onPageChange}
			onSortChange={onSortChange}
			cellRender={(cell) => <LoadingCell loading={loading} element={cell} />}
		>
			{renderColumns()}
			{commandCellType !== CommandCellType.WithoutActions && (
				<GridColumn
					title="Actions"
					cell={({ dataItem }) => (
						<CommandCell
							dataItem={dataItem}
							loading={loading}
							openDocumentsModal={openDocumentsModal}
							handleNavigate={handleNavigate}
							navigateItem={navigateItem}
							actionField={actionField}
							handleUpdate={handleUpdate}
							handleDelete={handleDelete}
							commandCellType={commandCellType}
							handleSelect={handleActionSelected}
							canDelete={canDelete}
							items={handleOptions(dataItem, handleMoreOptions as MoreOptions)}
						/>
					)}
					width="160px"
					headerClassName={styles.gridActionsHeader}
				/>
			)}
		</Grid>
	);
};
