import type { Page } from "@progress/kendo-react-dropdowns";
import type { GridPageChangeEvent } from "@progress/kendo-react-grid";
import NProgress from "nprogress";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SCREEN_WIDTH_1920 } from "../../../../../common/models/src/lib/constants/breakpoints.constants";
import { GOODS_PAGE_SIZE } from "../../../../../common/models/src/lib/constants/goods.constants";
import { LOAD_GOODS_GRID_COLUMNS } from "../../../../../common/models/src/lib/constants/grid-column.constants";
import { DEFAULT_TAKE_SIZE } from "../../../../../common/models/src/lib/constants/grid.constants";
import {
	JOB_STATUSES,
	JOB_STATUS_MAPPINGS,
} from "../../../../../common/models/src/lib/constants/job.constants";
import { LOADS_PAGE_SIZE } from "../../../../../common/models/src/lib/constants/load.constants";
import { CommandCellType } from "../../../../../common/models/src/lib/enums/command-cell.enum";
import { FilterItemType } from "../../../../../common/models/src/lib/enums/filter-item-type.enum";
import {
	ModalSize,
	ModalType,
} from "../../../../../common/models/src/lib/enums/modal.enums";
import type { IBase } from "../../../../../common/models/src/lib/interfaces/base.interface";
import type {
	IDateFilter,
	IFilterItem,
} from "../../../../../common/models/src/lib/interfaces/filter.interface";
import type {
	IGoodsForLoad,
	IGoodsResponseDto,
} from "../../../../../common/models/src/lib/interfaces/goods.interface";
import {
	useAppDispatch,
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../../../common/stores/src/lib/utils";
import { CelerumButton } from "../../../../../common/ui/src/lib/components/celerum-buttons/celerum-buttons.component";
import { CelerumConfirmModal } from "../../../../../common/ui/src/lib/components/celerum-confirm-modal/celerum-confirm-modal.component";
import { CelerumDetailsHeader } from "../../../../../common/ui/src/lib/components/celerum-details-header/celerum-details-header.component";
import { CelerumDropdownCategory } from "../../../../../common/ui/src/lib/components/celerum-dropdown-category/celerum-dropdown-category.components";
import { CelerumFilters } from "../../../../../common/ui/src/lib/components/celerum-filters/celerum-filters.component";
import { CelerumGrid } from "../../../../../common/ui/src/lib/components/celerum-grid/celerum-grid.component";
import { buildFilterQueryString } from "../../../../../common/utils/src/lib/helpers/query.helpers";
import { useOldLocalStorage } from "../../../../../common/utils/src/lib/hooks/use-local-storage.hook";
import {
	addGoodsForLoadAction,
	fetchGoodsForLoadAction,
} from "../../../../../goods/data-access/src/lib/goods.slice";
import { fetchJobTypesAction } from "../../../../../jobs/data-access/src/lib/job-types.slice";
import { LoadGoodsModalContent } from "../components/load-goods-modal-content/load-goods-modal-content.component";
import styles from "./load-goods-feature.module.css";

interface LoadGoodsFeatureProps {
	loadId?: string | undefined;
}

interface LoadGoodsFilterState {
	jobStatusFilter: IFilterItem[];
	jobTypeFilter: IFilterItem[];
	collectionDateFilter: IDateFilter[];
	deliveryDateFilter: IDateFilter[];
}

const initialFilterState: LoadGoodsFilterState = {
	jobStatusFilter: [],
	jobTypeFilter: [],
	collectionDateFilter: [],
	deliveryDateFilter: [],
};

export const LoadGoodsFeature = ({ loadId }: LoadGoodsFeatureProps) => {
	const dispatchWithNotifications = useAppDispatchWithNotifications();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const [searchParams] = useSearchParams();
	const uniqueId = searchParams.get("uniqueId");

	const { goods, total, loading, navbarExpanded, jobTypes } = useAppSelector(
		(state) => ({
			goods: state.goods.data,
			total: state.goods.total,
			loading: state.goods.loading,
			jobTypes: state.jobTypes.data,
			navbarExpanded: state.userInterface.navbarExpanded,
		}),
	);

	const [sort, setSort] = useState<string>("");
	const [page, setPage] = useState<Page>({ skip: 0, take: GOODS_PAGE_SIZE });
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const [searchFilter, setSearchFilter] = useState<string>("");
	const [computedFilters, setComputedFilters] = useState<string>("");
	const [openGoodsModal, setOpenGoodsModal] = useState<boolean>(false);
	const [selectedGoodsIds, setSelectedGoodsIds] = useState<Set<number>>(
		new Set<number>(),
	);
	const [selectedGoodsForLoad, setSelectedGoodsForLoad] = useState<
		IGoodsForLoad[]
	>([]);

	const [loadGoodsFilters, setLoadGoodsFilters] =
		useOldLocalStorage<LoadGoodsFilterState>(
			"loadGoodsFilterState",
			initialFilterState,
		);

	const renderedGoods = useMemo(() => {
		return goods?.map((goods) => {
			const collectionDate = new Date(goods?.collectionDate).toLocaleString();
			const deliveryDate = new Date(goods?.deliveryDate).toLocaleString();

			return {
				...goods,
				collectionDate,
				deliveryDate,
			};
		});
	}, [goods]);

	const renderedSelectedGoods = useMemo(() => {
		return renderedGoods.filter((goods) => {
			return selectedGoodsIds?.has(goods.id);
		});
	}, [renderedGoods, selectedGoodsIds]);

	const selectedGoodsWeight = useMemo(() => {
		const totalWeight = renderedSelectedGoods.reduce((accumulator, item) => {
			return accumulator + item.weight;
		}, 0);

		return totalWeight;
	}, [renderedSelectedGoods]);

	const renderedJobTypesList = useMemo(
		() =>
			jobTypes.map((jobType) => {
				return {
					id: jobType.name as unknown as number,
					name: jobType.name,
				};
			}),
		[jobTypes],
	) as IBase[];

	const handleResize = useMemo(() => {
		return new ResizeObserver((entries) => {
			for (const entry of entries) {
				setWindowWidth(entry.contentRect.width);
			}
		});
	}, []);

	const requestDataIfNeeded = (event: GridPageChangeEvent) => {
		const { skip, take } = event.page;
		for (let i = skip; i < skip + take && i < goods.length; i++) {
			/** if there is a row with ID -1, it means that we need to fetch more data. */
			if (goods[i]?.id === -1) {
				if (loading) {
					return;
				}

				const page = Math.ceil(skip / LOADS_PAGE_SIZE) + 1;
				dispatchWithNotifications({
					action: fetchGoodsForLoadAction,
					payload: {
						page,
						pageSize: LOADS_PAGE_SIZE,
						sort,
						computedFilters,
						loadId,
					},
					errorMessage: "Could not fetch goods.",
				});
				break;
			}
		}
	};

	const requestSortedData = (sort: string) => {
		setSort(sort);
		/**
		 * Always clear existing data and start fetching again
		 * from page 1.
		 */
		dispatchWithNotifications({
			action: fetchGoodsForLoadAction,
			payload: { page: 1, pageSize: LOADS_PAGE_SIZE, sort, computedFilters },
			errorMessage: "Could not fetch jobs.",
		});
	};

	const handleAddGoodsToLoad = async () => {
		const actionResult = await dispatchWithNotifications({
			action: addGoodsForLoadAction,
			payload: { loadId, goods: selectedGoodsForLoad },
			successMessage: "Goods added successfully.",
			errorMessage: "Could not add goods to load.",
		});

		if (addGoodsForLoadAction.fulfilled.match(actionResult)) {
			navigate(`/loads/${loadId}`);
		}
	};

	const configureSelectedGoods = () => {
		setSelectedGoodsForLoad(
			renderedSelectedGoods.map((goodsItem: IGoodsResponseDto) => ({
				goodsId: goodsItem.id,
				haveDeliveryOnRoute: false,
				havePickupOnRoute: false,
			})),
		);
	};

	const clearFilters = () => {
		setLoadGoodsFilters(initialFilterState);
	};

	useEffect(() => {
		const typesFilterLists = [];

		loadGoodsFilters.jobStatusFilter.length &&
			typesFilterLists.push(loadGoodsFilters.jobStatusFilter);
		loadGoodsFilters.jobTypeFilter.length &&
			typesFilterLists.push(loadGoodsFilters.jobTypeFilter);

		const combinedDateFilters = [
			...loadGoodsFilters.collectionDateFilter,
			...loadGoodsFilters.deliveryDateFilter,
		];

		const filters = buildFilterQueryString(
			searchFilter,
			[
				"uniqueId",
				"name",
				"customerName",
				"collectionLocationName",
				"deliveryLocationName",
			],
			combinedDateFilters,
			typesFilterLists,
		);
		setComputedFilters(filters);
		if (loadId) {
			if (
				searchFilter ||
				combinedDateFilters.length ||
				typesFilterLists.length
			) {
				dispatch(
					fetchGoodsForLoadAction({
						page: 1,
						pageSize: GOODS_PAGE_SIZE,
						filters,
						sort,
						loadId,
					}),
				);
			} else {
				dispatch(
					fetchGoodsForLoadAction({
						page: 1,
						pageSize: GOODS_PAGE_SIZE,
						sort,
						loadId,
					}),
				);
			}
		}
		setPage({ skip: 0, take: DEFAULT_TAKE_SIZE });
	}, [dispatch, loadId, loadGoodsFilters, searchFilter, sort]);

	useEffect(() => {
		dispatch(fetchJobTypesAction());
	}, [dispatch]);

	useEffect(() => {
		const target = document.documentElement;
		handleResize.observe(target);

		return () => {
			handleResize.unobserve(target);
		};
	}, [handleResize]);

	useEffect(() => {
		if (loading) {
			NProgress.start();
		} else {
			NProgress.done();
		}
	}, [loading]);

	return (
		<>
			<CelerumDetailsHeader
				loading={false}
				title={
					!navbarExpanded || windowWidth >= SCREEN_WIDTH_1920
						? `Add goods for Load ${uniqueId}`
						: undefined
				}
				subtitle={"Add your suitable goods."}
			>
				<div className={styles.headerContainer}>
					<div
						className={
							selectedGoodsIds.size
								? styles.selectedGoodsContainer__visible
								: styles.selectedGoodsContainer__hidden
						}
					>
						<CelerumDropdownCategory
							name="Selected goods"
							filtersCount={selectedGoodsIds.size}
							renderChevron={false}
						/>
						<div className={styles.selectedGoodsContainer__weightContainer}>
							<h1
								className={
									styles.selectedGoodsContainer__weightContainer__label
								}
							>
								Weight
							</h1>
							<h1
								className={
									styles.selectedGoodsContainer__weightContainer__value
								}
							>
								{selectedGoodsWeight}kg
							</h1>
						</div>
					</div>
					<div className={styles.filtersContainer}>
						<CelerumFilters
							setSearchFilter={setSearchFilter}
							setFilters={setLoadGoodsFilters}
							filters={{
								jobStatusFilter: {
									name: "Job Status",
									column: "jobStatus",
									values: JOB_STATUSES,
									type: FilterItemType.DROPDOWN,
								},
								jobTypeFilter: {
									name: "Job Type",
									column: "jobTypeName",
									values: renderedJobTypesList,
									type: FilterItemType.DROPDOWN,
								},
								collectionDateFilter: {
									name: "Collection Date",
									column: "collectionDate",
									type: FilterItemType.DATERANGE,
								},
								deliveryDateFilter: {
									name: "Delivery Date",
									column: "deliveryDate",
									type: FilterItemType.DATERANGE,
								},
							}}
							clearFilters={clearFilters}
							initialValues={{
								jobStatusFilter: loadGoodsFilters.jobStatusFilter,
								jobTypeFilter: loadGoodsFilters.jobTypeFilter,
								collectionDateFilter: loadGoodsFilters.collectionDateFilter,
								deliveryDateFilter: loadGoodsFilters.deliveryDateFilter,
							}}
						/>
						<CelerumButton
							disabled={!selectedGoodsIds.size}
							title="Continue"
							onClick={() => {
								configureSelectedGoods();
								setOpenGoodsModal(true);
							}}
						/>
					</div>
				</div>
			</CelerumDetailsHeader>
			<CelerumGrid
				page={page}
				setPage={setPage}
				columns={LOAD_GOODS_GRID_COLUMNS}
				data={renderedGoods}
				total={total}
				loading={loading}
				commandCellType={CommandCellType.WithoutActions}
				requestDataIfNeeded={requestDataIfNeeded}
				selectedItemsIds={selectedGoodsIds}
				setSelectedItemsIds={setSelectedGoodsIds}
				statusMappings={JOB_STATUS_MAPPINGS}
				requestSortedData={requestSortedData}
			/>
			<CelerumConfirmModal
				type={ModalType.Warning}
				title="Do you want to proceed?"
				subtitle="Are you sure you want to add the following goods?"
				size={ModalSize.Large}
				isOpen={openGoodsModal}
				handleClose={() => setOpenGoodsModal(false)}
				handleSubmit={handleAddGoodsToLoad}
			>
				<LoadGoodsModalContent
					selectedGoods={renderedSelectedGoods}
					setSelectedGoodsForLoad={setSelectedGoodsForLoad}
				/>
			</CelerumConfirmModal>
		</>
	);
};
